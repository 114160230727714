import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CustomWordCloud from '../../Common/InsightsWordCloud/CustomWordCloud';
import GraphDropdown from '../../Common/GraphDropDown/GraphDropdown';
import {
  Sentiment,
  Container,
  Button,
  CheckBoxContainer,
  Title,
} from './WordCloudAnalysis.styled';
import { format } from 'date-fns';
import TitleWithInfo from '../../Common/TitleWithInfo/TitleWithInfo';
import WordCloudDropDown from '../../Common/GraphDropDown/WordcloudDropDown';

const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;
const WordCloudAnalysis = ({
  child,
  showSwitchTab,
  showKeyWordDropDown,
  insightsData,
  hideDropdown,
  startDate,
  endDate,
  formatValue = 'dd-MM-yyyy',
}) => {
  const [selectedButton, setSeletedButton] = useState('sentiments');
  const [selectedEmotions, setSelectedEmotions] = useState([]);
  const [selectedSentiments, setSelectedSentiments] = useState([]);
  const [wordCloudType, setWordCloudType] = useState('Bigram');
  const [wordCloudTypeInsta, setWordCloudTypeInsta] = useState('Keywords');
  const [unigramDropDownData, setUnigramDropDownData] = useState(['Unigram', 'Bigram', 'Trigram']);

  let checkboxData;
  if (selectedButton === 'sentiments') {
    checkboxData = [
      {
        id: '1',
        name: 'Positive',
      },
      {
        id: '2',
        name: 'Negative',
      },
      {
        id: '3',
        name: 'Neutral',
        category: 'sentiment',
      },
    ];
  } else if (selectedButton === 'emotions') {
    checkboxData = [
      {
        id: '4',
        name: 'Joy',
      },
      {
        id: '5',
        name: 'Surprise',
      },
      {
        id: '6',
        name: 'Neutral',
        category: 'emotion',
      },
      {
        id: '7',
        name: 'Anger',
      },
      {
        id: '8',
        name: 'Disgust',
      },
      {
        id: '9',
        name: 'Sadness',
      },
      {
        id: '10',
        name: 'Fear',
      },
    ];
  }

  const getWordsData = () => {
    // Replace the following example data with your actual data creation logic
    const isPositiveSelected = selectedSentiments.includes('Positive');
    const isNegativeSelected = selectedSentiments.includes('Negative');
    const isNeutralSelected = selectedSentiments.includes('Neutral');

    const isJoySelected = selectedEmotions.includes('Joy');
    const isSurPriseSelected = selectedEmotions.includes('Surprise');
    const isNeutralEmotion = selectedEmotions.includes('Neutral');
    const isAngerSelected = selectedEmotions.includes('Anger');
    const isDisgustSelected = selectedEmotions.includes('Disgust');
    const isSadnessSelected = selectedEmotions.includes('Sadness');
    const isFearSelected = selectedEmotions.includes('Fear');

    const data = insightsData?.sentiment_wordcloud || [];
    

    const getSelectedWords = (data, type, selectedFlag, color) => {
      if (selectedFlag) {
        return data
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: color,
          }));
      }
      return [];
    };


    const emotionData = insightsData?.emotion_wordcloud || [];

    const wordsArray = [];
    const selectedWords = [];

    const wordCloudData = {
      // positiveUnigram : data?.positive_unigram?.length > 0 ? data?.positive_unigram?.slice(0, 100) : data?.positive_unigram || [],
      // negativeUnigram : data?.negative_unigram?.length > 0 ? data?.negative_unigram?.slice(0,100) : data?.negative_unigram || [],
      // neutralUnigram : data?.neutral_unigram?.length > 0 ? data?.neutral_unigram?.slice(0,100) : data?.neutral_unigram || [],
      positiveUnigram: data?.positive_unigram || [],
      negativeUnigram: data?.negative_unigram || [],
      neutralUnigram: data?.neutral_unigram || [],
      positiveHashtags: data?.positive_hashtags || [],
      positiveMentions: data?.positive_mentions || [],
      positiveEmojis: data?.positive_emojis || [],

      negativeHashtags: data?.negative_hashtags || [],
      negativeMentions: data?.negative_mentions || [],
      negativeEmojis: data?.negative_emojis || [],

      neutralHashtags: data?.neutral_hashtags || [],
      neutralMentions: data?.neutral_mentions || [],
      neutralEmojis: data?.neutral_emojis || [],

      positiveBigram: data?.positive_bigram || [],
      negativeBigram: data?.negative_bigram || [],
      neutralBigram: data?.neutral_bigram || [],
      positiveTrigram: data?.positive_trigram || [],
      negativeTrigram: data?.negative_trigram || [],
      neutralTrigram: data?.neutral_trigram || [],
      joyUnigram: emotionData?.joy_unigram || [],
      joyBigram: emotionData?.joy_bigram || [],
      joyTrigram: emotionData?.joy_trigram || [],
      joyHashtags: emotionData?.joy_hashtags || [],
      joyMentions: emotionData?.joy_mentions || [],
      joyEmojis: emotionData?.joy_emojis || [],
      surpriseUnigram: emotionData?.surprise_unigram || [],
      surPriseBigram: emotionData?.surprise_bigram || [],
      surpriseTrigram: emotionData?.surprise_trigram || [],
      surpriseHashtags: emotionData?.surprise_hashtags || [],
      surpriseMentions: emotionData?.surprise_mentions || [],
      surpriseEmojis: emotionData?.surprise_emojis || [],
      neutralEmotionUnigram: emotionData?.neutral_unigram || [],
      neutralEmotionBigram: emotionData?.neutral_bigram || [],
      neutralEmotionTrigram: emotionData?.neutral_trigram || [],
      neutralEmotionEmojis: emotionData?.neutral_emojis || [],
      neutralEmotionHashtags: emotionData?.neutral_hashtags || [],
      neutralEmotionMentions: emotionData?.neutral_mentions || [],
      angerUnigram: emotionData?.anger_unigram || [],
      angerBigram: emotionData?.anger_bigram || [],
      angerTrigram: emotionData?.anger_trigram || [],
      angerHashtags: emotionData?.anger_hashtags || [],
      angerEmojis: emotionData?.anger_emojis || [],
      angerMentions: emotionData?.anger_mentions || [],
      disgustUnigram: emotionData?.disgust_unigram || [],
      disgustTrigram: emotionData?.disgust_trigram || [],
      disgustBigram: emotionData?.disgust_bigram || [],
      disgustHashtags: emotionData?.disgust_hashtags || [],
      disgustMentions: emotionData?.disgust_mentions || [],
      disgustEmojis: emotionData?.disgust_emojis || [],
      sadnessUnigram: emotionData?.sadness_unigram || [],
      sadnessBigram: emotionData?.sadness_bigram || [],
      sadnessTrigram: emotionData?.sadness_trigram || [],
      sadnessHashtags: emotionData?.sadness_hashtags || [],
      sadnessMentions: emotionData?.sadness_mentions || [],
      sadnessEmojis: emotionData?.sadness_emojis || [],
      fearUnigram: emotionData?.fear_unigram || [],
      fearBigram: emotionData?.fear_bigram || [],
      fearTrigram: emotionData?.fear_trigram || [],
      fearHashtags: emotionData?.fear_hashtags || [],
      fearMentions: emotionData?.fear_mentions || [],
      fearEmojis: emotionData?.fear_emojis || [],

    };


    if (wordCloudTypeInsta == 'Hashtags') {
      selectedWords.push(
        ...getSelectedWords(wordCloudData.positiveHashtags, 'positiveHashtags', isPositiveSelected, 'positive'),
        ...getSelectedWords(wordCloudData.negativeHashtags, 'negativeHashtags', isNegativeSelected, 'negative'),
        ...getSelectedWords(wordCloudData.neutralHashtags, 'neutralHashtags', isNeutralSelected, 'neutral'),
        ...getSelectedWords(wordCloudData.joyHashtags, 'joyHashtags', isJoySelected, 'joy'),
        ...getSelectedWords(wordCloudData.surpriseHashtags, 'surpriseHashtags', isSurPriseSelected, 'surprise'),
        ...getSelectedWords(wordCloudData.neutralEmotionHashtags, 'neutralEmotionHashtags', isNeutralEmotion, 'neutralEmotion'),
        ...getSelectedWords(wordCloudData.disgustHashtags, 'disgustHashtags', isDisgustSelected, 'disgusted'),
        ...getSelectedWords(wordCloudData.sadnessHashtags, 'sadnessHashtags', isSadnessSelected, 'sadness'),
        ...getSelectedWords(wordCloudData.fearHashtags, 'fearHashtags', isFearSelected, 'fear'),
        ...getSelectedWords(wordCloudData.angerHashtags, 'angerHashtags', isAngerSelected, 'anger')
      );
    } else if (wordCloudTypeInsta == 'Mentions') {
      selectedWords.push(
        ...getSelectedWords(wordCloudData.positiveMentions, 'positiveMentions', isPositiveSelected, 'positive'),
        ...getSelectedWords(wordCloudData.negativeMentions, 'negativeMentions', isNegativeSelected, 'negative'),
        ...getSelectedWords(wordCloudData.neutralMentions, 'neutralMentions', isNeutralSelected, 'neutral'),
        ...getSelectedWords(wordCloudData.joyMentions, 'joyMentions', isJoySelected, 'joy'),
        ...getSelectedWords(wordCloudData.surpriseMentions, 'surpriseMentions', isSurPriseSelected, 'surprise'),
        ...getSelectedWords(wordCloudData.neutralEmotionMentions, 'neutralEmotionMentions', isNeutralEmotion, 'neutralEmotion'),
        ...getSelectedWords(wordCloudData.disgustMentions, 'disgustMentions', isDisgustSelected, 'disgusted'),
        ...getSelectedWords(wordCloudData.sadnessMentions, 'sadnessMentions', isSadnessSelected, 'sadness'),
        ...getSelectedWords(wordCloudData.fearMentions, 'fearMentions', isFearSelected, 'fear'),
        ...getSelectedWords(wordCloudData.angerMentions, 'angerMentions', isAngerSelected, 'anger')
      );
    } else if (wordCloudTypeInsta == "Emoji's") {
      selectedWords.push(
        ...getSelectedWords(wordCloudData.positiveEmojis, 'positiveEmojis', isPositiveSelected, 'positive'),
        ...getSelectedWords(wordCloudData.negativeEmojis, 'negativeEmojis', isNegativeSelected, 'negative'),
        ...getSelectedWords(wordCloudData.neutralEmojis, 'neutralEmojis', isNeutralSelected, 'neutral'),
        ...getSelectedWords(wordCloudData.joyEmojis, 'joyEmojis', isJoySelected, 'joy'),
        ...getSelectedWords(wordCloudData.surpriseEmojis, 'surpriseEmojis', isSurPriseSelected, 'surprise'),
        ...getSelectedWords(wordCloudData.neutralEmotionEmojis, 'neutralEmotionEmojis', isNeutralEmotion, 'neutralEmotion'),
        ...getSelectedWords(wordCloudData.disgustEmojis, 'disgustEmojis', isDisgustSelected, 'disgusted'),
        ...getSelectedWords(wordCloudData.sadnessEmojis, 'sadnessEmojis', isSadnessSelected, 'sadness'),
        ...getSelectedWords(wordCloudData.fearEmojis, 'fearEmojis', isFearSelected, 'fear'),
        ...getSelectedWords(wordCloudData.angerEmojis, 'angerEmojis', isAngerSelected, 'anger')
      );
    } else if (wordCloudType == 'Unigram') {
      selectedWords.push(
        ...getSelectedWords(wordCloudData.positiveUnigram, 'positiveUnigram', isPositiveSelected, 'positive'),
        ...getSelectedWords(wordCloudData.negativeUnigram, 'negativeUnigram', isNegativeSelected, 'negative'),
        ...getSelectedWords(wordCloudData.neutralUnigram, 'neutralUnigram', isNeutralSelected, 'neutral'),
        ...getSelectedWords(wordCloudData.joyUnigram, 'joyUnigram', isJoySelected, 'joy'),
        ...getSelectedWords(wordCloudData.surpriseUnigram, 'surpriseUnigram', isSurPriseSelected, 'surprise'),
        ...getSelectedWords(wordCloudData.neutralEmotionUnigram, 'neutralEmotionUnigram', isNeutralEmotion, 'neutralEmotion'),
        ...getSelectedWords(wordCloudData.disgustUnigram, 'disgustUnigram', isDisgustSelected, 'disgusted'),
        ...getSelectedWords(wordCloudData.sadnessUnigram, 'sadnessUnigram', isSadnessSelected, 'sadness'),
        ...getSelectedWords(wordCloudData.fearUnigram, 'fearUnigram', isFearSelected, 'fear'),
        ...getSelectedWords(wordCloudData.angerUnigram, 'angerUnigram', isAngerSelected, 'anger')
      );
    } else if (wordCloudType == 'Bigram') {
      selectedWords.push(
        ...getSelectedWords(wordCloudData.positiveBigram, 'positiveBigram', isPositiveSelected, 'positive'),
        ...getSelectedWords(wordCloudData.negativeBigram, 'negativeBigram', isNegativeSelected, 'negative'),
        ...getSelectedWords(wordCloudData.neutralBigram, 'neutralBigram', isNeutralSelected, 'neutral'),
        ...getSelectedWords(wordCloudData.joyBigram, 'joyBigram', isJoySelected, 'joy'),
        ...getSelectedWords(wordCloudData.surPriseBigram, 'surpriseBigram', isSurPriseSelected, 'surprise'),
        ...getSelectedWords(wordCloudData.neutralEmotionBigram, 'neutralEmotionBigram', isNeutralEmotion, 'neutralEmotion'),
        ...getSelectedWords(wordCloudData.disgustBigram, 'disgustBigram', isDisgustSelected, 'disgusted'),
        ...getSelectedWords(wordCloudData.sadnessBigram, 'sadnessBigram', isSadnessSelected, 'sadness'),
        ...getSelectedWords(wordCloudData.fearBigram, 'fearBigram', isFearSelected, 'fear'),
        ...getSelectedWords(wordCloudData.angerBigram, 'angerBigram', isAngerSelected, 'anger')
      );
    } else if (wordCloudType == 'Trigram') {
      selectedWords.push(
        ...getSelectedWords(wordCloudData.positiveTrigram, 'positiveTrigram', isPositiveSelected, 'positive'),
        ...getSelectedWords(wordCloudData.negativeTrigram, 'negativeTrigram', isNegativeSelected, 'negative'),
        ...getSelectedWords(wordCloudData.neutralTrigram, 'neutralTrigram', isNeutralSelected, 'neutral'),
        ...getSelectedWords(wordCloudData.joyTrigram, 'joyTrigram', isJoySelected, 'joy'),
        ...getSelectedWords(wordCloudData.surpriseTrigram, 'surpriseTrigram', isSurPriseSelected, 'surprise'),
        ...getSelectedWords(wordCloudData.neutralEmotionTrigram, 'neutralEmotionTrigram', isNeutralEmotion, 'neutralEmotion'),
        ...getSelectedWords(wordCloudData.disgustTrigram, 'disgustTrigram', isDisgustSelected, 'disgusted'),
        ...getSelectedWords(wordCloudData.sadnessTrigram, 'sadnessTrigram', isSadnessSelected, 'sadness'),
        ...getSelectedWords(wordCloudData.fearTrigram, 'fearTrigram', isFearSelected, 'fear'),
        ...getSelectedWords(wordCloudData.angerTrigram, 'angerTrigram', isAngerSelected, 'anger')
      );
    }

   
    // let newWordArray = wordsArray?.length > 0 ? wordsArray.sort((a, b) => b.value - a.value) : [];
    // console.log(wordCloudData, wordsArray, "wordsArray");

    return selectedWords;
  };

  const handleGraphSelect = (option) => {
    setWordCloudType(option);
  };
  const handleGraphSelectInsta = (option) => {
    console.log(option, "option");
    if (option == 'Keywords') {

      setUnigramDropDownData(['Unigram', 'Bigram', 'Trigram']);
    } else {

      handleGraphSelect('Unigram');
      setUnigramDropDownData(['Unigram']);
    }
    setWordCloudTypeInsta(option);
  };

  useEffect(() => {
    handleGraphSelect('Unigram');
    // handleGraphSelectInsta('Keywords');
    setSelectedSentiments(['Positive', 'Negative', 'Neutral']);
  }, []);

  const handleCheckboxChange = (event, checkboxType) => {
    const { id, checked } = event.target;
    const selectedCheckbox = checkboxData.find(
      (checkbox) => checkbox.id === id
    );

    if (checkboxType === 'sentiments') {
      if (checked) {
        setSelectedSentiments((prevSentiments) => [
          ...prevSentiments,
          selectedCheckbox.name,
        ]);
      } else {
        setSelectedSentiments((prevSentiments) =>
          prevSentiments.filter(
            (sentiment) => sentiment !== selectedCheckbox.name
          )
        );
      }
    } else if (checkboxType === 'emotions') {
      if (checked) {
        setSelectedEmotions((prevEmotions) => [
          ...prevEmotions,
          selectedCheckbox.name,
        ]);
      } else {
        setSelectedEmotions((prevEmotions) =>
          prevEmotions.filter((emotion) => emotion !== selectedCheckbox.name)
        );
      }
    }
  };

  const handleButtonClick = (option) => {
    if (option === 'emotions') {
      setSelectedSentiments([]);
      setSelectedEmotions([
        'Joy',
        'Surprise',
        'Neutral',
        'Anger',
        'Disgust',
        'Sadness',
        'Fear',
      ]);
    }
    if (option === 'sentiments') {
      setSelectedEmotions([]);
      setSelectedSentiments(['Positive', 'Negative', 'Neutral']);
    }
    setSeletedButton(option);
  };
  return (
    <Sentiment>
      <Title>
        <TitleWithInfo
          title={'Word cloud Analysis'}

          infoText={
            `A word cloud based on user sentiments or emotions, generated from keywords, hashtags, mentions, or emojis. The cloud can be represented using unigram, bigram, or trigram phrases to highlight key topics and trends.`}
        />
      </Title>

      {/* <Title>Word cloud Analysis</Title> */}
      {showSwitchTab ? (
        <></>
      ) : (
        <Container>
          <div>
            <Button
              selected={selectedButton === 'sentiments'}
              onClick={() => handleButtonClick('sentiments')}
            >
              Sentiments
            </Button>
            <Button
              selected={selectedButton === 'emotions'}
              onClick={() => handleButtonClick('emotions')}
            >
              Emotions
            </Button>
          </div>
          {!hideDropdown && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
              <GraphDropdown
                options={['Keywords', 'Hashtags', 'Mentions', "Emoji's"]}
                onSelectGraph={handleGraphSelectInsta}
                defaultOption='Keywords'
                placement='right'
              />
              {showKeyWordDropDown && (
                <WordCloudDropDown
                  onSelect={(data) => {
                    console.log(data);
                  }}
                />
              )}
              {
                wordCloudTypeInsta == 'Keywords' ?
                  <GraphDropdown
                    options={unigramDropDownData}
                    onSelectGraph={handleGraphSelect}
                    defaultOption={wordCloudType}
                    placement='right'
                  />
                  :

                  <></>
              }
            </div>
          )}
        </Container>
      )}
      {!showSwitchTab ? (
        selectedButton && (
          <CheckBoxContainer>
            {checkboxData.map((checkbox, index) => (
              <div key={index}>
                <input
                  type='checkbox'
                  id={checkbox.id}
                  checked={
                    selectedSentiments.includes(checkbox.name) ||
                    selectedEmotions.includes(checkbox.name)
                  }
                  onChange={(event) =>
                    handleCheckboxChange(event, selectedButton)
                  }
                />
                <label htmlFor={checkbox.id}>{checkbox.name}</label>
                {/* Add more checkboxes as needed */}
              </div>
            ))}
          </CheckBoxContainer>
        )
      ) : (
        <></>
      )}

      <CustomWordCloud
        words={getWordsData()}
        selectedSentiments={selectedSentiments}
        selectedEmotions={selectedEmotions}
        sentimentData={insightsData?.sentiment_wordcloud}
      />
      <Span>
        {` This data is observed from ${startDate} to ${endDate}`}

        {/* This data is observed from {startDate && format(startDate, formatValue)} to {endDate && format(endDate, formatValue)} */}
      </Span>
    </Sentiment>
  );
};

export default WordCloudAnalysis;
