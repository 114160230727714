// WordCloudComponent.js
import { scaleLinear } from 'd3-scale';
import React from 'react';
import Cloud from 'react-d3-cloud';

const CustomWordCloud = ({ words, selectedSentiments, selectedEmotions, sentimentData }) => {

  const minFontSize = 10;
  const maxFontSize = 100;

  const minWordValue = Math?.min(...words.map((word) => word.value));
  const maxWordValue = Math?.max(...words.map((word) => word.value));

  const fontSizeScale = scaleLinear()
    .domain([minWordValue, maxWordValue])  // Using the domain [1, 7]
    .range([3, 10]);  // The range [0, 100]

  const fontSizeMapper = word => {

    let wordLog = fontSizeScale(word?.value);
    // console.log(wordLog,word);
    return Math?.round(wordLog) || 0;
  };

  const fontWeightMapper = word => {

    if (!word || word.value === undefined) return '400'; // Default weight if word or value is invalid

    // Normalize the word's value between min and max
    const normalizedValue = (word.value - minWordValue) / (maxWordValue - minWordValue);

    // Map normalized value to font weight range (400 to 700)
    const fontWeight = Math.round(400 + (normalizedValue * 300));

    return fontWeight.toString();
  };

  // console.log(fontSizeScale(1), minWordValue, maxWordValue, "fontSizeScale");

  // console.log(fontSizeScale(20),"fontSizeScale");

  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontSizes: [minFontSize, maxFontSize],
    font: 'Arial'
  };

  let maxValue = words[0]?.value;
  let minValue = words[words.length - 1]?.value;
  const diff = maxValue - minValue;

  const fill = word => {

    // Assign color based on sentiment
    if (word.color === 'positive') {
      // return '#73EBA4';
      return 'rgba(3, 198, 82, 1)';
    } if (word.color === 'negative') {
      // return '#FC6758'
      return 'rgba(243, 27, 27, 1)';
    } if (word.color === 'neutral') {
      // return 'rgb(245 188 44)'
      return 'rgb(242 196 81)';
      // return 'rgba(251, 214, 121, 1)';
    } if (word.color === 'joy') {
      // return '#FE00D5'
      return 'rgba(218, 6, 184, 1)';
    } if (word.color === 'surprise') {
      // return '#B7DC68'
      return 'rgba(3, 198, 82, 1)';
    } if (word.color === 'neutralEmotion') {
      // return '#F5CC61'
      return 'rgb(242 196 81)';
      // return 'rgba(251, 214, 121, 1)';
    } if (word.color === 'anger') {
      // return '#F74945'
      return 'rgba(238, 19, 7, 1)';
    } if (word.color === 'disgusted') {
      // return '#9071CE'
      return 'rgba(84, 30, 192, 1)';
    } if (word.color === 'sadness') {
      // return '#4B9AE9'
      return 'rgba(21, 112, 203, 1)';
    } if (word.color === 'fear') {
      // return '#FC9E58'
      return 'rgba(255, 153, 0, 1)';
    }
  }

  const rotate = word => Math.floor(Math.random() * 2) * 90;
  // Define a color scale for the word cloud

  // const options = {
  //   font: 'Arial',
  //   rotations: 2,
  //   rotationAngles: [-90, 0],
  // };

  return (
    <div style={{ width: '100%', height: '55vh', display: words?.length > 0 ? '' : 'flex', justifyContent: words?.length > 0 ? '' : 'center', alignItems: words?.length > 0 ? '' : 'center' }}>
      {
        words?.length > 0 ?
          <Cloud
            data={words}
            rotate={rotate}
            width={300}
            height={100}
            padding={0}
            font={options.font}
            fill={fill}
            // fontSize={(word) => Math.log2(word.value) * 2}
            // fontSize={(word) => diff > 1 || diff < -1 ? Math.sqrt(word.value) * 2 : 5}
            // fontSize={options.fontSizes}
            fontSize={fontSizeMapper}
            // fontWeight={fontWeightMapper}
            fontWeight={700}
          />

          :
          'Insufficient Data'
      }

      {/* <Resizable
          // defaultSize={{
          //   width: 300,
          //   height: 100,
          // }}
          style={resizeStyle}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <ReactWordcloud options={options} words={words} />
          </div>
        </Resizable> */}

    </div>
  );
};

export default CustomWordCloud;
