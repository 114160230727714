import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { CrossIcon, CrossIconBig, CrossIconWhite, EmojiHeartIcon, FacebookLogo, InstagramLogo, sort_icon } from '../../Common/SvgIcon/CommonSvgIcons';
import Pagination from '../Pagination/Pagination';
import CollapsibleText from '../ReviewText/collapsibleText';
import { Favorite, ThumbUp, Verified } from '@mui/icons-material';

// Styled components
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the popup is on top of other content */
`;

const PopupContainer = styled.div`
  width: ${(props) => props?.popupwidth ? props?.popupwidth : '58%'}; /* Adjust the width as needed */
  background-color: white;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: ${(props) => props.topic ? '-10px' : '-70px'};
  /*  overflow-y: scroll; */
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  /* color: white; */
  padding: 18px 9px;
  border-radius: 0px;
`;

const PopupBoderBottom = styled.div`
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background: rgba(217, 217, 217, 1);
`;

const IconSpan = styled.span`
  //display:flex;
  margin: 10px 0px 0px 25px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(99, 99, 99);
`;

const CloseIcon = styled.span`
  //float:right;
  margin-right: 15px;
display:flex;

  & > svg {
    fill: blue;
    stroke: ${(pop) => (pop.selectedChannel === 'Flipkart' ? 'none' : 'none')};
  }
`;

const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
  height:460px;
  margin-bottom:20px;
  span {
    display: flex;
    // margin-left: 25px;

    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
`;

const Wrapper = styled.div`
  // margin: 50px 25px;
  height: 100%;
  /* width: 100%; */
  margin:10px;
  .header {
    color: #636363;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;

    & > div:nth-child(2),
    & > div:nth-child(3) {
      color: #636363;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;
const RowWrapper = styled.div`
  & > div {
    width: 100%;
    min-height: 120px;
    box-sizing: border-box;
    margin-top: 15px;
    border-radius: 15px;
    border: 1px solid #d9d9d9;
    padding: 20px 0;
  }
`;
const RowData = styled.div`
  display: grid;
  grid-template-columns: 45% 1fr 1fr 1fr;
  gap: 5px;
  text-align: left;
  color: #2a2929;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;

  & .review {
    padding-left: 15px;

    & > div:nth-child(1) {
      color: #2a2929;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  & > div:nth-child(2),
  & > div:nth-child(3) {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
  }

  & > div:not(:first-child) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .filterColumn {
    cursor: pointer;
    display: flex;
    align-items: center;

  }

  & .profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Open Sans;
    font-size: 15px;
    font-weight: 600;

    & :last-child {
    //   color: #636363;
    }
  }
`;

const SpanEmoji = styled.span`
    // border: 1px solid;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
    background: ${(props) => props.color ? props.color : '#4080ff'};
    color: white;
    display:flex;
    align-items: center;
    justify-content: center;
    
    color: white !important;
    margin-left: 2px;
    margin-right: 2px;
`;
const SearchWrapper = styled.div`
  // display: flex;
  // margin-left: -40px;
  // margin-bottom: ;
  // margin-bottom: ${(props) => (props.selectedChannel === 'Amazon' ? '105px' : '0px')};

    display: grid;
    grid-template-columns: 70% 14% 170px;
    gap: 25px;
    margin-top:20px;

    .flexWrap {
      
    display: flex;
    margin-left: -50px;
    }
  span {
    // position: relative;
    // left: 50px;
    // top: 45%;
        position: relative;
    left: 60px;
    // /* top: 35%; */
    height: 48px;
    display: flex;
    justify-content: initial;
    align-items: center;
    // margin-top: 10px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  width: ${(props) => props.topic ? '135px' : '170px'};
  height: 50px;
  padding: 7px 21px;
  justify-content: center;
  align-items: center;
  // margin: 0px 0px 0px 20px;
  color: white;

    outline: none; /* Remove default outline */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  border-radius:25px;
  // border-radius: 100px;
  background: ${({ backcolor }) => backcolor || '#353cf7'} ;
  border: none;
`;

const Input = styled.input`
  margin: 0px 0px 0px 30px;
  padding-left: 40px;
  width: 100%;
  height: 45px;
  border-radius: 15px;
  border: 1px solid #a2a2a2;

  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
    outline: none; /* Remove default outline */
  font-weight: 500;
  &:focus {
  border: 1px solid #a2a2a2;
    outline: none; /* Remove default outline */
  }
`;

const Line = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  background: #dddddd;
`;

// Component
const PoPup = ({ channel, isOpen = false, columns = [],
    PageSize = 8, onClose, selectedChannel = '', searchType = '', setInstaDetails, popupwidth = '58%', topic = false }) => {
    const dispatch = useDispatch();

    const [commentsData, setCommentsData] = useState([
        {
            comment: 'shoes are incredibly comfortable and perfect for running.shoes are incredibly comfortable and perfect for running.shoes are incredibly comfortable and perfect for running.shoes are incredibly comfortable and perfect for running. Highly Puma shoes are incredibly comfortable and perfect for running. HighlyPuma shoes are incredibly comfortable and perfect for running. Highly Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'Pavan',
            likes: 123,
            replies: 12,
            Verified: true,
            time: '1w'

        }, {
            comment: 'Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'Teja',
            likes: 12,
            replies: 12,
            Verified: true,
            time: '1w'

        },
        {
            comment: 'incredibly comfortable and perfect for running. Highly',
            user_name: 'Siva',
            likes: 14,
            replies: 12,
            time: '1w',
            Verified: false,

        },
        {
            comment: 'comfortable and perfect for running. Highly',
            user_name: 'Naveen',
            likes: 24,
            replies: 112,
            time: '1w',
            Verified: false,

        },
        {
            comment: 'Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'Bhargav',
            likes: 124,
            replies: 1,
            Verified: false,
            time: '1w'

        }, {
            comment: 'Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'Sri',
            likes: 100,
            Verified: true,
            replies: 2,
            time: '1w'

        }, {
            comment: 'Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'Hari',
            likes: 1,
            replies: 1112,
            Verified: true,
            time: '1w'

        }, {
            comment: 'Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'Manoj',
            likes: 0,
            replies: 1221,
            Verified: true,
            time: '1w'

        },
        {
            comment: 'Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'Jai',
            likes: 4,
            replies: 122,
            time: '1w',
            Verified: false,

        },
        {
            comment: 'Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'Lav',
            likes: 54,
            replies: 12,
            time: '1w',
            Verified: false,

        },
        {
            comment: 'Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'Kusha',
            likes: 343,
            replies: 12,
            Verified: false,
            time: '1w'

        }, {
            comment: 'Puma shoes are incredibly comfortable and perfect for running. Highly',
            user_name: 'John',
            likes: 566,
            Verified: true,
            replies: 12,
            time: '1w'

        }
    ]);
    const [currentPage, setCurrentPage] = useState(1);
    if (!isOpen) {
        return null;
    }

    const getCurrentTableData = () => {
        let firstPageIndex = (currentPage - 1) * PageSize;
        let lastPageIndex = firstPageIndex + PageSize;

        return commentsData.length > 0 ? commentsData.slice(firstPageIndex, lastPageIndex) : [];
    };
    const handleClose = () => {
        // dispatch(topicEmptysearchInstaValue())
        onClose()
    }

    function formatNumber(val) {
        if (val >= 1000000) {
            return (val / 1000000).toFixed(1) + 'M'; // Convert to million
        } else if (val >= 1000) {
            return (val / 1000).toFixed(1) + 'k';
        } else if (val > 0) {
            return val.toString();
        } else {
            return '';
        }
    }
    if (!isOpen) {
        return null;
    }

    const handleFilter = (index) => {
        const tempReviews = [...commentsData];
        /* tempReviews.sort(
          (a, b) =>
            b[columns[index].toLowerCase()] - a[columns[index].toLowerCase()]
        ); */
        if (columns[index] === 'Profile') {
            tempReviews?.sort((a, b) => a.user_name.localeCompare(b.user_name))
        } else if (columns[index] === 'Comment') {
            tempReviews?.sort((a, b) => a.comment.localeCompare(b.comment))

        } else if (columns[index] == 'Reactions') {
            tempReviews?.sort(
                (a, b) =>
                    b.likes - a.likes
            );
        } else {

            tempReviews?.sort(
                (a, b) =>
                    b[columns[index].toLowerCase()] - a[columns[index].toLowerCase()]
            );
        }


        setCommentsData([...tempReviews]);
    };


    return (
        <PopupOverlay>
            <PopupContainer topic={topic} popupwidth={popupwidth}>
                <PopupHeader >
                    <IconSpan>
                        {channel == 'instagram' ?  InstagramLogo : channel == 'facebook' ? FacebookLogo : ''} Comments {commentsData?.length > 0 ? `(${commentsData?.length})` : '' }
                    </IconSpan>
                    <CloseIcon selectedChannel={selectedChannel} onClick={handleClose}>
                        {CrossIconBig}
                    </CloseIcon>
                </PopupHeader>
                <PopupBoderBottom>

                </PopupBoderBottom>
                <PopupBody>
                    <Wrapper>
                        <RowData className='header' >
                            {columns.map((column, i) => {
                                // if (filterCol.includes(i + 1)) {
                                return (
                                    <div
                                        className='filterColumn'
                                        key={i}
                                        onClick={() => handleFilter(i)}
                                    >
                                        {column}&nbsp;
                                        {sort_icon}
                                    </div>
                                );
                                // }

                                // return <div key={i} style={{ paddingLeft: '15px' }}>{column}</div>;
                            })}
                        </RowData>
                        <RowWrapper>
                            {getCurrentTableData()?.length > 0 && getCurrentTableData()?.map((message, index) =>
                            (
                                <RowData key={index} >
                                    <div className='review'>
                                        <div>
                                            Post on{' '}
                                            {/* {formatDate(
                    channel === 'twitter'
                      ? message.date
                      : message.date || message['Reviewed on']
                  )} */}
                                        </div>
                                        <div>
                                            <CollapsibleText
                                                text={
                                                    //   channel === 'twitter'
                                                    message.comment
                                                    // : message.posts || message.review_meta || message?.text
                                                }
                                                link=''

                                            />
                                            <div style={{ marginBottom: '-10px' }}>
                                                {message.time}
                                            </div>
                                        </div>

                                    </div>
                                    {/* columns.includes('Profile') && */}
                                    {(
                                        <div className='profile'>
                                            <span >{message.user_name}
                                                <span className='verified' style={{ color: 'rgb(25, 118, 210)', height: '23px', marginLeft: '8px' }}>
                                                    <Verified style={{ color: 'rgb(25, 118, 210)' }} />
                                                </span></span>

                                            {/* {channel === 'instagram' && (
                    <span style={{ fontSize: '12px' }}>
                      {formatNumberVal(message['followers_count'] || 0)}
                    </span>
                  )} */}
                                        </div>
                                    )}
                                    <div className='helpful'>
                                    {channel == 'facebook' ? '' :formatNumber(message?.likes) || 0} 
                                        {channel == 'facebook' ?
                                            <span style={{ marginLeft: '5px', color: 'rgba(222, 13, 13, 1)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <SpanEmoji color='#4080ff'>
                                                    <ThumbUp style={{
                                                        height: '100%',
                                                        width: '90%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        fontSize: '12px'
                                                    }} />
                                                </SpanEmoji>
                                                <SpanEmoji color='#ed5168'>
                                                    <Favorite style={{
                                                        height: '100%',
                                                        width: '90%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        fontSize: '12px'
                                                    }} />
                                                </SpanEmoji>
                                                <span style={{marginLeft:'2px',marginRight:'4px'}}>
                                                    {EmojiHeartIcon}
                                                </span>
                                                <span>{formatNumber(message?.likes) || 0} </span>
                                            </span> : <span style={{ marginLeft: '5px', color: 'rgba(222, 13, 13, 1)' }}><Favorite /></span>}
                                    </div>
                                    <div>
                                        {(formatNumber(message?.replies)) || 0}
                                    </div>

                                </RowData>
                            )
                            )}
                        </RowWrapper>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={commentsData.length}
                            pageSize={PageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                            reviewsData={commentsData}
                        />
                    </Wrapper>


                </PopupBody>
            </PopupContainer>
        </PopupOverlay>
    );
};

export default PoPup;
