import React, { useState, useEffect } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleQuantile } from 'd3-scale';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import TitleWithInfo from '../../Common/TitleWithInfo/TitleWithInfo';

// Styled components
const TooltipContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 9999;
`;

const Title = styled.span`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  gap: 10px;
`;

const Wrapper = styled.div`
  padding: 20px 40px;
  line-height: 25px;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
`;

const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    margin: 0px;
  }
`;

const MapContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;

  border: 2px solid #fff;
`;

const PositiveCircle = styled(Circle)`
  background: #5ce390;
`;

const NeutralCircle = styled(Circle)`
  background: #f5cc61;
`;

const NegativeCircle = styled(Circle)`
  background: #fc6758;
`;

const DEFAULT_COLOR = '#EEE';
const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;
  min-height: 300px;
  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Data transformation function (assumed to be imported already)
const stateNameToId = {
  /* Add your state mapping */
};
const transformData = (data) => {
  return Object.entries(data).map(([state, value]) => ({
    id: stateNameToId[state] || state,
    country: value?.country,
    value: value?.percentage,
    neutral_percentage: value?.neutral_percentage || 0,
    negative_percentage: value?.negative_percentage || 0,
    positive_percentage: value?.positive_percentage || 0,
    posts_count: value?.posts_count || 0,
    likes_count: value?.likes_count || 0,
    replies_count: value?.replies_count || 0,
    views_count: value?.views_count || 0,
    reposts_count: value?.reposts_count || 0,
    color: 'rgba(53, 60, 247, 1)',
    // color: '#27a434'
    // color:`#${Math.floor(Math.random() * 16777215).toString(16)}`
    // topics: value.topics,
  }));
};

// Main Map Component
function MapChart({
  mapData,
  colorRange,
  type = '',
  startDate,
  endDate,
  infoText,
  channel,
}) {
  const [positiveVal, setPositiveVal] = useState(0);
  const [negativeVal, setNegativeVal] = useState(0);
  const [neutralVal, setNeutralVal] = useState(0);
  const [likes, setLikes] = useState(0);
  const [posts, setPosts] = useState(0);
  const [replies, setReplies] = useState(0);
  const [views, setViews] = useState(0);
  const [reposts, setReposts] = useState(0);
  const [tooltipContent, setTooltipContent] = useState('');
  const [data, setData] = useState([]);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (mapData) {
      if (channel === 'twitter') {
        var temp = transformData(mapData);
        temp = temp?.sort((a, b) => {
          return b?.posts_count - a?.posts_count;
        });
        temp = temp?.map((item, index) => ({
          ...item,
          color:
            index === 0
              ? '#353CF7'
              : index === 1
              ? '#5D63F9'
              : index === 2
              ? '#868AFA'
              : index === 3
              ? '#AEB1FC'
              : '#D7D8FD',
        }));
        setData(temp);
      } else {
        setData(transformData(mapData));
      }
    }
  }, [mapData]);

  const colorScale = scaleQuantile()
    .domain(data.map((d) => d.value))
    .range(colorRange);

  const onMouseEnter = (geo, current = { value: 'NA' }) => {
    return (event) => {
      if (channel === 'twitter') {
        setTooltipContent(geo?.properties?.name);
      } else {
        setTooltipContent(
          `${geo.properties.name}: ${
            current?.value && current?.value != 'NA' ? `${current?.value}%` : 0
          }`
        );
      }
      setTooltipPosition({ x: event.clientX, y: event.clientY });
      setPositiveVal(current?.positive_percentage || 0);
      setNegativeVal(current?.negative_percentage || 0);
      setNeutralVal(current?.neutral_percentage || 0);
      if (channel === 'twitter') {
        setLikes(current?.likes_count || 0);
        setPosts(current?.posts_count || 0);
        setReplies(current?.replies_count || 0);
        setViews(current?.views_count);
        setReposts(current?.reposts_count);
      }
    };
  };

  const onMouseLeave = () => {
    setTooltipContent('');
  };

  // Tooltip style adjustment based on viewport
  const getTooltipStyle = () => {
    const viewportWidth = window.innerWidth;
    const tooltipWidth = 140; // Tooltip width
    let adjustedX = tooltipPosition.x + 10;

    // Adjust tooltip position if it's too far on the right
    if (tooltipPosition.x + tooltipWidth > viewportWidth) {
      adjustedX = viewportWidth - tooltipWidth - 10; // 10px margin from the right
    }
    // Adjust tooltip position if it's too far on the left
    else if (tooltipPosition.x < 0) {
      adjustedX = 10; // 10px margin from the left
    }
    if (tooltipPosition?.y > window?.innerHeight - 300) {
      return {
        top: window?.innerHeight - 300,
        left: adjustedX,
        // width: `${tooltipWidth}px`,
      };
    }
    return {
      top: tooltipPosition.y + 10,
      left: adjustedX,
      // width: `${tooltipWidth}px`,
    };
  };

  return (
    <Wrapper>
      <Title>
        <TitleWithInfo
          title={'World Map'}
          infoText={
            infoText !== null
              ? infoText
              : `This map visualizes the proportion of news stories from different countries within the overall news dataset. `
          }
        />
      </Title>
      {data?.length < 1 ? (
        <NoDataWrapper>
          {/* No Data Available  */}Insufficient Data
        </NoDataWrapper>
      ) : (
        <>
          <MapContainer>
            {tooltipContent && (
              <TooltipContainer style={getTooltipStyle()}>
                <div
                  style={{
                    borderBottom: '1px solid white',
                    padding: '5px',
                    textAlign: 'center',
                  }}
                >
                  {/* United States - 50% */}

                  {tooltipContent}
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '3px',
                    }}
                  >
                    {channel !== 'twitter' && <PositiveCircle />}
                    <p
                      style={{
                        margin: '0px',
                        display: 'grid',
                        gridTemplateColumns: '69px 10px 50px',
                        alignItems: 'center',
                        gap: '2px',
                      }}
                    >
                      <span>
                        {channel === 'twitter' ? 'Posts' : 'Positive'}
                      </span>
                      <span>-</span>
                      {channel === 'twitter' ? (
                        <span>{posts}</span>
                      ) : (
                        <span>{positiveVal ? positiveVal : 0}% </span>
                      )}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '3px',
                    }}
                  >
                    {channel !== 'twitter' && <NegativeCircle />}
                    <p
                      style={{
                        margin: '0px',
                        display: 'grid',
                        gridTemplateColumns: '69px 10px 50px',
                        alignItems: 'center',
                        gap: '2px',
                      }}
                    >
                      <span>
                        {channel !== 'twitter' ? 'Negative' : 'Likes'}
                      </span>
                      <span>-</span>
                      {channel !== 'twitter' ? (
                        <span>{negativeVal ? negativeVal : 0}%</span>
                      ) : (
                        <span>{likes ? likes : 0}</span>
                      )}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '3px',
                    }}
                  >
                    {channel !== 'twitter' && <NeutralCircle />}
                    <p
                      style={{
                        margin: '0px',
                        display: 'grid',
                        gridTemplateColumns: '69px 10px 50px',
                        alignItems: 'center',
                        gap: '2px',
                      }}
                    >
                      <span>
                        {channel !== 'twitter' ? 'Neutral' : 'Replies'}
                      </span>
                      <span>-</span>
                      {channel !== 'twitter' ? (
                        <span>{neutralVal ? neutralVal : 0}%</span>
                      ) : (
                        <span>{replies ? replies : 0}</span>
                      )}
                    </p>
                  </div>
                  {channel === 'twitter' && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          marginTop: '3px',
                        }}
                      >
                        <p
                          style={{
                            margin: '0px',
                            display: 'grid',
                            gridTemplateColumns: '69px 10px 50px',
                            alignItems: 'center',
                            gap: '2px',
                          }}
                        >
                          <span>Views </span>
                          <span>-</span>

                          <span>{views ? views : 0}</span>
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          marginTop: '3px',
                        }}
                      >
                        <p
                          style={{
                            margin: '0px',
                            display: 'grid',
                            gridTemplateColumns: '69px 10px 50px',
                            alignItems: 'center',
                            gap: '2px',
                          }}
                        >
                          <span>Reposts </span>
                          <span>-</span>
                          <span>{reposts ? reposts : 0}</span>
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </TooltipContainer>
            )}
            <ComposableMap
              projection={'geoMercator'}
              // projection="geoEquirectangular"
              projectionConfig={{
                scale: 110, // Adjust the scale to zoom in or out
                center: [0, 45], // Center the map at (0, 45) (longitude, latitude)
              }}
              width={870}
              height={500}
            >
              <Geographies geography={require('./world.topo.json')}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const current = data.find(
                      (s) => s.country === geo.properties.name
                    );
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={
                          current ? colorScale(current.value) : DEFAULT_COLOR
                        }
                        style={{
                          default: {
                            fill: current?.color ? current?.color : '#D3D3D3', // Default color for countries
                            outline: 'none',
                            stroke: '#ffffff', // White border between countries
                            strokeWidth: 0.5, // Thickness of the border
                          },

                          // default: {
                          //     outline: 'none',
                          // },
                          hover: {
                            fill: 'rgb(143 146 231)',
                            // fill: '#ccc',
                            // transition: 'all 250ms',
                            outline: 'none',
                          },
                          pressed: {
                            outline: 'none',
                          },
                        }}
                        onMouseEnter={onMouseEnter(geo, current)}
                        onMouseLeave={onMouseLeave}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>
          </MapContainer>
          <Span>
            {channel !== 'twitter' ? (
              <p
                style={{
                  display: data?.length > 5 ? 'grid' : 'flex',
                  gap: '11px',

                  // display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                }}
              >
                {data?.map(
                  (i) =>
                    i?.country && (
                      <span>
                        {i?.country} - {`${i.value}%`}
                      </span>
                    )
                )}

                {/* <span>India - 80%</span><span>USA - 08% </span>   <span>China - 08%</span> <span> Europe - 12%</span></p> */}
              </p>
            ) : (
              <p></p>
            )}
            <p
              style={{ width: '295px' }}
            >{` This data is observed from ${startDate} to ${endDate}`}</p>
          </Span>
        </>
      )}
    </Wrapper>
  );
}

export default MapChart;
