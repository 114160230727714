import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import ClusterAnalysisGraph from '../../../../components/ClusterAnalysisGraph/ClusterAnalysisGraph';
import { useParsedEndDate, useParsedStartDate } from '../../../../utils/ParseDate';
import GraphHOC from '../../../../Common/GraphHOC/GraphHOC';
import BarGraph from '../../../../Common/BarGraph/BarGraph';
import TitleWithInfo from '../../../../Common/TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Container = styled.div`
  min-height: 100px;
  padding: 25px 20px 25px 50px;

  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: #636363;

  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin-left: -35px;
  margin-bottom: 15px;
`;
const Title2 = styled.div`
  color: #636363;

  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  margin-left: -35px;
  margin-bottom: 15px;
`;
const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  margin-top: 15px;
`;
const GraphWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: end;
  // border-left: 2px solid rgba(0, 0, 0, 0.38);
  // border-bottom: 2px solid rgba(0, 0, 0, 0.38);
  padding: 20px;
  box-sizing: border-box;
`;
const ClusterAnalysisTab = ({
  cluster_analysis = {},
  startDate = '',
  endDate = '',
  child = [
    {
      "id": "4aa",
      "name": "Sentiments",
      "type": "checkbox",
      "value": false,
      "display": false
    },
    {
      "id": "4ab",
      "name": "Emotions",
      "type": "checkbox",
      "value": false,
      "display": false
    }
  ],
  formatValue = 'dd-MM-yyyy'
}) => {

  const formattedDate = useParsedStartDate(false, '', startDate, formatValue);
  const endDateParsed = useParsedEndDate(false, '', endDate, formatValue);


  if (Object.keys(cluster_analysis).length === 0) {
    return <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>Insufficient Data</h2>;
  }

  // const sentimentsData = [
  //   {
  //     name: 'Positive',
  //     value: parseFloat(summary?.positive_sentiment || 0),
  //     fill: '#A1FCC6',
  //   },
  //   {
  //     name: 'Negative',
  //     value: parseFloat(summary?.negative_sentiment || 0),
  //     fill: '#FF8C86',
  //   },
  //   {
  //     name: 'Neutral',
  //     value: parseFloat(summary?.neutral_sentiment || 0),
  //     fill: '#FFE092',
  //   },
  // ];

  // const emotionsData = [
  //   {
  //     name: 'Neutral',
  //     value: summary?.neutral || 0,
  //     fill: 'rgb(245, 188, 44)'
  //   },
  //   {
  //     name: 'Surprise',
  //     value: summary?.surprise || 0,
  //     fill: '#B7DC68',
  //   },
  //   {
  //     name: 'Joy',
  //     value: summary?.joy || 0,
  //     fill: '#FE00D5',
  //   },
  //   {
  //     name: 'Fear',
  //     value: summary?.fear || 0,
  //     fill: '#FC9E58',
  //   },
  //   {
  //     name: 'Sadness',
  //     value: summary?.sadness || 0,
  //     fill: '#4B9AE9',
  //   },
  //   {
  //     name: 'Anger',
  //     value: summary?.anger || 0,
  //     fill: '#F74945',
  //   },
  //   {
  //     name: 'Disgust',
  //     value: summary?.disgust || 0,
  //     fill: '#9071CE',
  //   },
  // ];


  // const sentimentsDatarenderScreen = {
  //   'Bar Graph': (
  //     <BarGraph
  //       data={title == 'Emotions' ? emotionsData :sentimentsData}
  //       type={'%'}
  //       /*  borderRadius={'0px 12px 12px 0px'} */
  //       barHeight={'32px'}
  //     />
  //   ),
  // };

  return (
    <Wrapper >
      {
        child?.length > 0 && (child[0]?.value || child[1]?.value) &&
        <Container className="pdf-page" style={{
          pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
        }}>
          <Title><TitleWithInfo
            title={'Cluster Analysis'}
            infoText={`A correlation matrix is a table showing correlation coefficients
            between variables. Each cell in the table shows the correlation
            between two variables`}
          /> </Title>
          <Title2>Summaries of Sentiments and Emotions </Title2>
          {
            cluster_analysis?.emotion_data?.circle?.length > 0 ?
              <ul style={{ margin: '0px' }}>

                {cluster_analysis?.emotion_data?.circle?.map((i, index) => (
                  <div style={{
                    borderBottom: ((index == cluster_analysis?.emotion_data?.circle?.length - 1) || cluster_analysis?.emotion_data?.circle?.length <= 1) ? '' : '1px solid #cccccc',
                    marginTop: ((index == 0) ? '0px' : '15px')
                  }}>


                    <li>
                      {i?.summary}
                    </li>
                    <div style={{
                      display: 'flex',
                      // alignItems: 'center',
                      gap: '10px',
                      marginBottom: '25px'
                    }}>
                      <div style={{ width: '100%' }}>
                        <h3> Emotions</h3>
                        <BarGraph
                          data={
                            [
                              {
                                name: 'Neutral',
                                value: i?.neutral || 0,
                                fill: 'rgb(245, 188, 44)'
                              },
                              {
                                name: 'Surprise',
                                value: i?.surprise || 0,
                                fill: '#B7DC68',
                              },
                              {
                                name: 'Joy',
                                value: i?.joy || 0,
                                fill: '#FE00D5',
                              },
                              {
                                name: 'Fear',
                                value: i?.fear || 0,
                                fill: '#FC9E58',
                              },
                              {
                                name: 'Sadness',
                                value: i?.sadness || 0,
                                fill: '#4B9AE9',
                              },
                              {
                                name: 'Anger',
                                value: i?.anger || 0,
                                fill: '#F74945',
                              },
                              {
                                name: 'Disgust',
                                value: i?.disgust || 0,
                                fill: '#9071CE',
                              },
                            ]

                          }
                          type={'%'}
                          /*  borderRadius={'0px 12px 12px 0px'} */
                          barHeight={'32px'}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <h3> Sentiments</h3>
                        <BarGraph
                          data={
                            [
                              {
                                name: 'Positive',
                                value: parseFloat(cluster_analysis?.sentiment_data?.circle?.[index]?.positive_sentiment || 0),
                                fill: '#A1FCC6'
                              },
                              {
                                name: 'Negative',
                                value: parseFloat(cluster_analysis?.sentiment_data?.circle?.[index]?.negative_sentiment || 0),
                                fill: '#FF8C86'
                              },
                              {
                                name: 'Neutral',
                                value: parseFloat(cluster_analysis?.sentiment_data?.circle?.[index]?.neutral_sentiment || 0),
                                fill: '#FFE092'
                              }

                            ]

                          }
                          type={'%'}
                          /*  borderRadius={'0px 12px 12px 0px'} */
                          barHeight={'32px'}
                        />
                      </div>

                    </div>

                  </div>

                ))
                }
              </ul>

              :
              <GraphWrapper style={{
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                {/* No Data Available */}Insufficient Data
              </GraphWrapper>
}


          <Footer>
            This data is observed from {formattedDate} to {endDateParsed}
          </Footer>
        </Container>
      }


    </Wrapper>
  );
};

export default ClusterAnalysisTab;
