import React, { useState } from 'react';
import RequestDemoForm from '../../components/RequestDemoForm/RequestDemoForm';
import styled from 'styled-components';
import LandingHeader from '../../components/LandingPageComponents/LandingHeader/LandingHeader';
import LandingFooter from '../../components/LandingPageComponents/LandingFooter/LandingFooter';
const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-y: hidden;
  background: linear-gradient(101deg, #5937c8 0%, #391c99 100%);
  @media only screen and (max-width: 768px) {
    max-height: ${(props) => (props.menuOpen ? '100vh' : 'unset')};
  }
`;
const HeaderWrapper = styled.div`
  width: 100%;
  top: 0;
  background: #ebebeb;
  z-index: 9;
`;
const BookADemo = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Wrapper menuOpen={menuOpen}>
      <HeaderWrapper>
        <LandingHeader
          setMenuOpen={(data) => {
            setMenuOpen(data);
          }}
        />
      </HeaderWrapper>
      <RequestDemoForm />
      <LandingFooter />
    </Wrapper>
  );
};
export default BookADemo;
