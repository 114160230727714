import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import BarGraph from '../../../../../Common/BarGraph/BarGraph';
import {
  ExportIcon,
  GreenEllipseIcon,
  NeutralEllipseIcon,
  NeutralIcon,
  RedEllipseIcon,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';
import SentimentDrillDown from '../../../../../components/SentimentsDrilldown/SentimenDrillDOwn';
import CollapsibleText from '../../../../../components/ReviewText/collapsibleText';
import { useParsedEndDate, useParsedStartDate } from '../../../../../utils/ParseDate';
import TitleWithInfo from '../../../../../Common/TitleWithInfo/TitleWithInfo';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  //height:100vw;
  flex-direction: column;
  //background:red;
`;

export const BoxWrapper = styled.div`
  width: 43px;
  display: flex;
  margin-top: 15px;
  align-items: center;
  height: 42px;
  flex-shrink: 0;
`;

export const HeadingSpan = styled.span`
  display: flex;
  margin: 30px 0px 0px 30px;
  color: #636363;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0px 0px 30px;
  width: 95%;
  height: auto;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  p {
    display: flex;
    width: 411px;
    flex-direction: column;
    flex-shrink: 0;
    margin: 100px 0px 0px 20px;

    color: #636363;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`;

export const PieDiv = styled.div`
  display: flex;
  margin: 0px 0px 0px 20px;

  div {
    display: flex;
    margin: 0px 0px 0px 30px;
  }
`;

const HeadSpan3 = styled.span`
  display: flex;
  margin: 35px 0px 0px 30px;
  color: #636363;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const DivWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  span {
    margin-right: 15px;
  }

  p {
    margin-left: 40px;
  }
`;

const HeadingSpan1 = styled(HeadingSpan)`
  margin-top: 20px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin: 40px 0px 0px 25px;
`;

const ContentWrapper5 = styled.div`
  display: flex;
  margin: 40px 0p 0px 20px;
  flex-direction: column;
  width: 47%;
  height: 350px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  &>div: first-child {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  p {
    color: #636363;

    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;

    margin-left: 20px;
  }
`;

const ReviewsWrapper = styled.div`
  width: 95%;
  height: auto;
  margin: 30px 0px 0px 30px;

  border-radius: 15px;
  border: 1px solid #d9d9d9;

  &>div: first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const UserSpan = styled.span`
  cursor: pointer;
  height: 18px;
  padding: 5px 20px;
  border: 1px solid #e4e4e4;
  border-radius: ${(props) => (props.selected ? '6px 0px 0px 6px' : '1px')};
  background-color: ${(props) => (props.selected ? '#353CF7' : 'transparent')};
  color: ${(props) => (props.selected ? '#FFF' : 'black')};

  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 30px 0px 0px 0px;
`;

const ReviewContainer = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  width: 90%;
  height: auto;
  border-radius: 15px;
  padding: 20px;
  margin: 10px 0px 0px 25px;

  background: rgba(92, 227, 144, 0.2);

  display: grid;
  grid-template-columns: 92% 8%;
  align-items: center;
  gap: 20px;

  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin: 0px 0px 10px 40px;
  }
`;

const Container1 = styled.div`
  display: flex;
  flex-direction: column;

  &>span: first-child {
    color: #000;

    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }

  &>span: last-child {
    width: 100%;
    text-align: center;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    margin-left: -15px;
  }
`;

const ReviewContainer1 = styled(ReviewContainer)`
  background: rgba(249, 98, 89, 0.18);
`;

const ReviewContainer2 = styled(ReviewContainer)`
  background: rgba(254, 222, 141, 0.37);
  margin-bottom: 20px;
`;

/* const RedCircle = styled(Circle)`
  background: #fc6758;
`;

const NeutralCricle = styled(Circle)`
  background: #f5cc61;
`; */

const TopPostWrapper = styled.div`
  & > div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 1em;
    border-radius: 15px;
    padding: 20px;

    .reviewText {
      flex: 1;
      border-right: 1px solid #d9d9d9;
      padding: 0 10px;
    }
    .reviewCount {
      width: 10%;
      text-align: center;
    }
  }

  .positiveReview {
    background: rgba(92, 227, 144, 0.2);
  }

  .negativeReview {
    background: rgba(249, 98, 89, 0.18);
  }

  .neutralReview {
    background: rgba(254, 222, 141, 0.37);
  }

  .peopleLiked {
    font-size: 10px;
  }
`;

const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;

  border: 2px solid #fff;
`;

const PositiveCircle = styled(Circle)`
  background: #5ce390;
`;

const NeutralCircle = styled(Circle)`
  background: #f5cc61;
`;

const NegativeCircle = styled(Circle)`
  background: #fc6758;
`;

const Title = styled.p`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 0px 15px;
`;

const TopRepostWrapper = styled.div`
  margin-top: 30px;
  margin-left: 25px;
  width: 92%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  align-items: center;
`;

const PostCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  padding: 0 18px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3em;
  }
`;

const Footer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  margin-top: 30px;
`;

function SentimentsTab({
  chatterData,
  plan,
  selectedReport = {},
  filteredData,
  filterOption,
  view,
  filteredStartDate,
  filteredEndDate,
  reset,
  
  endDate,
  startDate,
  formatValue = "dd/yyyy/MM",
}) {
  const [userType, setUserType] = useState('verified');
  const [sentimentGraph, setSentimentGraph] = useState('DonutChart');

  const componentRef = useRef(null);

  let planSpecificData;
  if (selectedReport) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = selectedReport?.data;
    } else if (plan === 'BrandEssence') {
      // planSpecificData = selectedReport?.BrandAstraEssence;
      planSpecificData = selectedReport?.data;
    } else {
      // planSpecificData = selectedReport?.BrandAstraElite;
      planSpecificData = selectedReport?.data;
    }
  } else if (chatterData) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = chatterData?.data;
    } else if (plan === 'BrandEssence') {
      // planSpecificData = chatterData?.BrandAstraEssence;
      planSpecificData = chatterData?.data;
    } else {
      // planSpecificData = chatterData?.BrandAstraElite;
      planSpecificData = chatterData?.data;
    }
  }

 /*  if (Object.keys(planSpecificData?.sentiments_tab).length === 0) {
    return (
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        No data available
      </h2>
    );
  } */

  /*  const verifiedAndNonVerifiedData =
    planSpecificData?.sentiments.Verified_non_verified_users;

  const mostHelpfulReview =
    plan === 'BrandAstraPro' || plan === 'AstraLite'
      ? planSpecificData?.sentiments.most_positive
      : '';
  const negativeReview =
    plan === 'BrandAstraPro' || plan === 'AstraLite'
      ? planSpecificData?.sentiments.most_negative
      : ''; */

  const averageRating =
    userType === 'verified'
      ? planSpecificData?.sentiments?.verified_purchase_impact
          .verified_average_rating
      : planSpecificData?.sentiments?.verified_purchase_impact
          .non_verified_avg_rating;

  const handleOptionClick = (option) => {
    setUserType(option);
  };

  const startDateParsed = useParsedStartDate(reset, filteredStartDate, startDate, formatValue) ;
const endDateParsed = useParsedEndDate(reset, filteredEndDate, endDate, formatValue) ;

 
  return (
    <Wrapper>
      <SentimentDrillDown
        data={filteredData ? filteredData?.sentiments_tab : planSpecificData?.sentiments_tab}
        startDate={startDateParsed  }
        endDate={endDateParsed }
        filterOption={filterOption}
        view={view}
        channelName = {selectedReport?.channel}
        infoText='This chart categorizes user reviews into three sentiments: Positive, Negative, and Neutral. It displays the most frequently discussed topics about the product, classified by their sentiment.'
       
      />
      {chatterData?.channel === 'amazon' || selectedReport?.channel === 'amazon' ? (
        <TopRepostWrapper>
          <Title className='title'>
          <TitleWithInfo
              title={'Top Helpful Reviews'}
              infoText={`This represents the post with the highest engagement in terms of helpful Reviews, categorized by sentiment (positive, negative, or neutral).`}
           
            />
            </Title>
          <PostCountWrapper>
            <div>
              <PositiveCircle />
              {`Total Positive Helpful Reviews - ${ 
                filteredData
                  ? filteredData?.sentiments_tab?.top_helpfull_reviews
                      ?.no_of_positive_helpful_reviews
                  : planSpecificData?.sentiments_tab?.top_helpfull_reviews?.no_of_positive_helpful_reviews
              }`}
            </div>
            <div>
              <NegativeCircle />
              Total Negative Helpful Reviews -{' '}
              {filteredData
                ? filteredData?.sentiments_tab?.top_helpfull_reviews
                    .no_of_negative_helpful_reviews
                : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                    ?.no_of_negative_helpful_reviews                  }
            </div>
            <div>
              <NeutralCircle />
              Total Neutral Helpful Reviews -{' '}
              {filteredData
                ? filteredData?.sentiments_tab?.top_helpfull_reviews
                    ?.no_of_neutral_helpful_reviews
                : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                    ?.no_of_neutral_helpful_reviews}
            </div>
          </PostCountWrapper>
          <TopPostWrapper>
            <div className='positiveReview'>
              <PositiveCircle />
              <div className='reviewText'>
                Most Positive Helpful Review
                <br />
                <br />
                <CollapsibleText text={filteredData
                  ? filteredData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_positive_helpful_reviews?.most_positive_helpful_review || 'Insufficient Data'
                  : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_positive_helpful_reviews?.most_positive_helpful_review || 'Insufficient Data'} background='rgba(92, 227, 144, 0.2)' />
              </div>
              <div className='reviewCount'>
                {filteredData
                  ? filteredData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_positive_helpful_reviews?.most_positive_helpful_review_count
                  : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_positive_helpful_reviews?.most_positive_helpful_review_count}
                <div className='peopleLiked'>
                People found <br /> this helpful
                </div>
              </div>
            </div>
            <div className='negativeReview'>
              <NegativeCircle />
              <div className='reviewText'>
                Most Negative Helpful Review
                <br />
                <br />
                <CollapsibleText text={filteredData
                  ? filteredData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_negative_helpful_reviews?.most_negative_helpful_review || 'Insufficient Data'
                  : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_negative_helpful_reviews?.most_negative_helpful_review || 'Insufficient Data'} background='rgba(249, 98, 89, 0.18)'/>
              </div>
              <div className='reviewCount'>
                {filteredData
                  ? filteredData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_negative_helpful_reviews?.most_negative_helpful_review_count
                  : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_negative_helpful_reviews?.most_negative_helpful_review_count}
                <div className='peopleLiked'>
                People found <br /> this helpful
                </div>
              </div>
            </div>
            <div className='neutralReview'>
              <NeutralCircle />
              <div className='reviewText'>
                Most Neutral Helpful Review
                <br />
                <br />
                <CollapsibleText text={filteredData
                  ? filteredData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_neutral_helpful_reviews?.most_neutral_helpful_review || 'Insufficient Data'
                  : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_neutral_helpful_reviews?.most_neutral_helpful_review || 'Insufficient Data'} background='rgba(254, 222, 141, 0.37)'/>
              </div>
              <div className='reviewCount'>
                {filteredData
                  ? filteredData?.sentiments_tab.top_helpfull_reviews
                      ?.top_neutral_helpful_reviews?.most_neutral_helpful_review_count
                  : planSpecificData?.sentiments_tab?.top_helpfull_reviews
                      ?.top_neutral_helpful_reviews?.most_neutral_helpful_review_count}
                <div className='peopleLiked'>
                People found <br /> this helpful
                </div>
              </div>
            </div>
          </TopPostWrapper>
          <Footer>
            This data is observed from {startDateParsed} to {endDateParsed}
            {/* startDate={startDateParsed  }
        endDate={endDateParsed } */}
        
            {/* {reset
              ? filteredStartDate
              : chatterData?.start_date || selectedReport?.start_date}{' '} */}
            
            
            {/* {reset
              ? filteredEndDate
              : chatterData?.end_date || selectedReport?.end_date} */}
          </Footer>
        </TopRepostWrapper>
      ) : (
        <TopRepostWrapper>
          <p className='title'>
          <TitleWithInfo
              title={'Top Liked Reviews'}
              infoText={`This represents the post with the highest engagement in terms of Liked Reviews, categorized by sentiment (positive, negative, or neutral).`}
           
            />
            </p>

          <PostCountWrapper>
            <div>
              <PositiveCircle />
              {`Total Positive Liked Reviews - ${
                filteredData
                  ? filteredData?.sentiments_tab?.top_liked_reviews

                      ?.total_positive_liked_reviews
                  : planSpecificData?.sentiments_tab?.top_liked_reviews

                      ?.total_positive_liked_reviews
              }`}
            </div>
            <div>
              <NegativeCircle />
              Total Negative Liked Reviews -{' '}
              {filteredData
                ? filteredData?.sentiments_tab?.top_liked_reviews

                    ?.total_negative_liked_reviews
                : planSpecificData?.sentiments_tab?.top_liked_reviews

                    ?.total_negative_liked_reviews}
            </div>
            <div>
              <NeutralCircle />
              Total Neutral Liked Reviews -{' '}
              {filteredData
                ? filteredData?.sentiments_tab?.top_liked_reviews

                    ?.total_neutral_liked_reviews
                : planSpecificData?.sentiments_tab?.top_liked_reviews

                    ?.total_neutral_liked_reviews}
            </div>
          </PostCountWrapper>
          <TopPostWrapper>
            <div className='positiveReview'>
              <PositiveCircle />
              <div className='reviewText'>
                Most Positive Liked Review
                <br />
                <br />
                <CollapsibleText text={filteredData
                  ? filteredData?.sentiments_tab?.top_liked_reviews?.top_positive_liked_reviews?.most_positive_liked_review || 'Insufficient Data'
                  : planSpecificData?.sentiments_tab?.top_liked_reviews?.top_positive_liked_reviews?.most_positive_liked_review || 'Insufficient Data'} background='rgba(92, 227, 144, 0.2)'/>
              </div>
              <div className='reviewCount'>
                {filteredData
                  ? filteredData?.sentiments_tab?.top_liked_reviews

                      ?.top_positive_liked_reviews?.most_positive_liked_review_count 
                  : planSpecificData?.sentiments_tab?.top_liked_reviews

                      ?.top_positive_liked_reviews?.most_positive_liked_review_count}
                <div className='peopleLiked'>
                  People Liked
                </div>
              </div>
            </div>
            <div className='negativeReview'>
              <NegativeCircle />
              <div className='reviewText'>
                Most Negative Liked Review
                <br />
                <br />
                <CollapsibleText text={filteredData
                  ? filteredData?.sentiments_tab?.top_liked_reviews

                      ?.top_negative_liked_reviews?.most_negative_helpful_review|| 'Insufficient Data'
                  : planSpecificData?.sentiments_tab?.top_liked_reviews

                      ?.top_negative_liked_reviews?.most_negative_liked_review || 'Insufficient Data'} background='rgba(249, 98, 89, 0.18)' />
              </div>
              <div className='reviewCount'>
                {filteredData
                  ? filteredData?.sentiments_tab?.top_liked_reviews?.top_negative_liked_reviews?.most_negative_liked_review_count 
                  : planSpecificData?.sentiments_tab?.top_liked_reviews

                      ?.top_negative_liked_reviews?.most_negative_liked_review_count}
                <div className='peopleLiked'>
                  People Liked
                </div>
              </div>
            </div>
            <div className='neutralReview'>
              <NeutralCircle />
              <div className='reviewText'>
                Most Neutral Liked Review
                <br />
                <br />
                <CollapsibleText text={filteredData
                  ? filteredData?.sentiments_tab?.top_liked_reviews?.top_neutral_liked_reviews?.most_neutral_liked_review || 'Insufficient Data'
                  : planSpecificData?.sentiments_tab?.top_liked_reviews

                      ?.top_neutral_liked_reviews?.most_neutral_liked_review || 'Insufficient Data'} background='rgba(254, 222, 141, 0.37)'/>
              </div>
              <div className='reviewCount'>
                {filteredData
                  ? filteredData?.sentiments_tab?.top_liked_reviews
                      ?.top_neutral_liked_reviews?.most_neutral_liked_review_count
                  : planSpecificData?.sentiments_tab?.top_liked_reviews

                      ?.top_neutral_liked_reviews?.most_neutral_liked_review_count}
                <div className='peopleLiked'>
                  People Liked
                </div>
              </div>
            </div>
          </TopPostWrapper>
          <Footer>
            {`This data is observed from ${startDateParsed} to ${endDateParsed}`}
            {/* startDate={startDateParsed  }
        endDate={endDateParsed } */}
        {/* { startDateParsed  }{' '}  */}
            {/* {reset
              ? filteredStartDate
              : chatterData?.start_date || selectedReport?.start_date}{' '} */}
            {/* to{' '}
            {endDateParsed} */}
            {/* This data is observed from{' '}
            {reset
              ? filteredStartDate
              : chatterData?.start_date || selectedReport?.start_date}{' '}
            to{' '}
            {reset
              ? filteredEndDate
              : chatterData?.end_date || selectedReport?.end_date} */}
          </Footer>
        </TopRepostWrapper>
      )}
    </Wrapper>
  );
}

export default SentimentsTab;