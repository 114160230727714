import React from 'react';
import { ErrorMessage, KeywordWrapper, Label, SubLabel } from '../TopicBuilder/MainContent/QueryBuilder/TopicBuilder.styled';
import ConnectChannels from '../TopicBuilder/MainContent/ConnectedChannels/ConnectedChannels';
import ChannelDropdown from '../ChatterTracker/MainComponent/ChatterTrackerBuilder/ShoppingChannels/ChannelDropdown';
import { DateWrapper, DescForTopic, IconDiv, InfoDivWrapper, InfoWrapper } from './ViewCompetitive.styled';
import Select from '../../Common/Select/Select';
import DateSlider from '../../Common/DateSlider/dateSlider';
import { SmallQuestionIcon } from '../../Common/SvgIcon/CommonSvgIcons';
import { useState } from 'react';
import PopUp from '../../Common/PopUp/PopUp';
import { useNavigate } from 'react-router-dom';
import Button from '../../Common/Button/Button';
import styled from 'styled-components';
const CancelButton = styled.span`
  cursor: pointer;
  ${'' /* float: right; */}
  margin: 20px 20px 0px 0px;

  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
const SelectChannelPage = ({
    showSecondModule,
    topicCount,
    chooseFocus,
    subLabelVal,
    descVal,
    handleTopicOneChange, topicOptions,
    selectedTopicOne,
    startDateOne,
    endDateOne,
    onDateSliderChange,
    dateHandler,
    setStartDateOne,
    setEndDateOne,
    setShowModal,
    setChannel,
    channelType,
    handleChannelSelect,
    channel,
    channelError,
    showModal
}) => {

    const navigate = useNavigate();
    const [infoText, setInfotext] = useState(false)
    return (

        <div>
            {
                showModal && (
                    <div>

                        <PopUp onClose={() => setShowModal(false)}>
                            <div>
                                <p style={{ color: 'red' }}>No Topic Available</p>
                                <div >
                                    <p>It seems there's no topic currently available.
                                        Would you like to select a different channel or go back to the home screen?</p>
                                </div>
                                <br />
                                <CancelButton onClick={() => {
                                    navigate('/competitiveIntelligence/home')
                                    setShowModal(false)
                                    setChannel(null)
                                }}>
                                    Back to home
                                </CancelButton>
                                <Button onClick={() => {
                                    setShowModal(false)
                                    setChannel(null)
                                }}
                                    value={'Select Different Channel'}
                                    style={{ height: 40, width: 200 }}
                                />
                            </div>
                        </PopUp>
                    </div>
                )
            }
            {
                chooseFocus &&

                <InfoDivWrapper>
                    <p style={{ margin: '0px', fontSize: '17px', fontWeight: '500', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Choose your focus</p>
                    <IconDiv data-testid="iconDivElement" onMouseOver={() => setInfotext(true)} onMouseLeave={() => setInfotext(false)}>
                        {SmallQuestionIcon}
                        {(infoText &&
                            <InfoWrapper>
                                Custom analysis means using special tools to find exactly what
                                you're looking for.
                            </InfoWrapper>
                        )}
                    </IconDiv>
                </InfoDivWrapper>
            }
            <KeywordWrapper>
                <Label style={{
                    color: showSecondModule ? 'black' : 'rgb(209 201 201)'
                }}>
                    Channels <span>*</span>
                </Label>
                <br />
                <SubLabel style={{
                    color: showSecondModule ? 'black' : 'rgb(209 201 201)'
                }}>{subLabelVal}</SubLabel>
                <div style={{ width: '40%' }}>
                    {channelType === 'Topic' ? <ConnectChannels

                        showSecondModule={!showSecondModule}
                        handleConnectChannelsToggle={handleChannelSelect}
                        channel={channel}
                    /> :
                        <ChannelDropdown
                            showSecondModule={!showSecondModule} onOptionSelect={handleChannelSelect} />
                    }
                </div>
                {channelError && <ErrorMessage>{channelError}</ErrorMessage>}
            </KeywordWrapper>
            {
                channel !== null && showModal !== true &&
                <div>
                    {/* <SubLabel style={{marginTop: 40}}>Select topics to generate comprehensive competitive intelligence reports</SubLabel> */}
                    <div>
                        <div style={{ borderRadius: 6, border: '1px solid, #A2A2A2', padding: 20, marginTop: '30px' }}>

                            <Label style={{ marginLeft: '0px', color: showSecondModule ? 'black' : 'rgb(209 201 201)' }}>Topic <span>*</span></Label>
                            <DescForTopic style={{
                                color: showSecondModule ? 'black' : 'rgb(209 201 201)'
                            }}>{descVal}</DescForTopic>
                            <div style={{ width: '40%' }}>
                                <Select
                                    showSecondModule={!showSecondModule}
                                    onChange={handleTopicOneChange}
                                    option={topicOptions || []}
                                    placeholder='Select preferred topic'
                                    style={{
                                        placeholderColor: '#636363',
                                        borderColor: '#d9d9d9'
                                    }}
                                    checkbox={false}
                                />
                            </div>
                            {
                                selectedTopicOne !== null &&
                                <div>
                                    {/* <div style={{ marginTop: 20, marginBottom: 20, borderRadius: 6, border: '1px solid #d9d9d9' }}> */}
                                    <DateSlider
                                        startDate={selectedTopicOne?.start_date}
                                        endDate={selectedTopicOne?.end_date}
                                        // progress={{ start: 20, end: 60 }}
                                        onChange={onDateSliderChange}
                                        topicCount={topicCount}
                                        setStartDateOne={setStartDateOne}
                                        setEndDateOne={setEndDateOne}
                                    />
                                    {/* </div> */}
                                    {/* <DateWrapper>
                                        <div>
                                            <div>Start Date:</div>
                                            <input
                                                type='date'
                                                name='firstPostDate'
                                                value={startDateOne}
                                                onChange={(e) => dateHandler(e, 1)}
                                            />
                                        </div>
                                        <div>
                                            <div>End Date:</div>
                                            <input
                                                type='date'
                                                name='lastPostDate'
                                                value={endDateOne}
                                                onChange={(e) => dateHandler(e, 1)}
                                            />
                                        </div>
                                    </DateWrapper> */}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
export default SelectChannelPage;