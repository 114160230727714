import React from 'react';
import styled from 'styled-components';
import TitleWithInfo from '../../../../Common/TitleWithInfo/TitleWithInfo';

const Image = styled.img`
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 90%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  text-align: center;
`;

const TableRow = styled.tr`
  border: 1px solid #ddd;
  height: auto;
  margin: 20px;
`;

const TableCell = styled.td`
  padding: 8px; /* Cell padding */
  border-right: 1px solid #ddd;
    word-break: break-all;
`;

const TableHead = styled.th`
  height: 50px;
  padding: 10px;
  background: #ededed;
  border-right: 1px solid #ddd;
    word-break: break-all;
    min-width: 61px;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  margin-top: 30px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 40px 10px;
`;
const Title = styled.div`
  color: #636363;

  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin-left: 35px;
  margin-top: 15px;
`;
const ParagraphTitle = styled.p`
margin:0px;
display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:5px;
    min-height:${(props) => props?.titleText ? '50px' : ''};
`;

const CustomAnalysisTab = ({ AnalysisFilter = '', showCustom = true, infoText = '' }) => {
  let valObj = AnalysisFilter ? JSON.parse(AnalysisFilter) : '{}';

  // console.log(valObj, AnalysisFilter, "valObj");        // "Generate a bar chart showing the count of tweets by each gender"
  // console.log(parsedData.is_table);     // false
  // console.log(parsedData.response.type);        // "image"
  // console.log(parsedData.response.image_url);


  const renderResult = () => {
    if (valObj?.is_table && Array.isArray(valObj?.response?.data) && valObj?.response?.data?.length > 0) {
      const keys = Object.keys(valObj?.response?.data?.[0]); // Get the keys from the first object
      return (
        <Table>
          <thead>
            <TableRow>
              {keys.map((key, index) => (
                <TableHead key={index}>{key}</TableHead>
              ))}
            </TableRow>
          </thead>
          <tbody>
            {valObj?.response?.data.map((item, index) => (
              <TableRow key={index}>
                {keys.map((key, idx) => (
                  <TableCell key={idx}>{item[key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </Table>
      );
    } else if (valObj?.is_table == false) {
      return <Image src={valObj?.response?.image_url} />;
    } else {
      return null;
    }
  };

  return (
    <div>
      {
        showCustom ?

          <Title>
            <ParagraphTitle>
              <TitleWithInfo
                title={'Custom Analysis '}
                infoText={infoText}
              />
            </ParagraphTitle> 
            </Title>
          :
          <></>
      }

      <Wrapper>
        <div>
          {valObj?.query}
        </div>

        <Container>


          {renderResult()}
        </Container>
      </Wrapper>

    </div>
  );
};


export default CustomAnalysisTab;