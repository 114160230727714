import React, { useEffect } from 'react';
import styled from 'styled-components';
import NewSideBar from '../../components/NewSideBar/NewSideBar';
import { useParams } from 'react-router-dom';
import HomePage from './MainContent/HomePage/HomePage';
import ActiveTopic from '../ActiveTopic/ActiveTopic';
import { useDispatch, useSelector } from 'react-redux';
import { activeTopicList } from '../../store/redux/slices/topicSlice';
import AddNewTopic from './MainContent/AddNewTopic/AddNewTopic';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: auto;
  // background:red;
`;

const TopicListener = () => {
  const { screenId } = useParams();

  const dispatch = useDispatch();
  const { activeTopicListData } = (useSelector((state) => state.topicBuilder) || []);

  
  useEffect(() => {
    if (!activeTopicListData) {
      dispatch(activeTopicList());
    }
  }, [activeTopicListData, dispatch]);


  /* const subTabs = [
    {
      id: '1a',
      val: 'New Topic',
      type: 'add', //If you want to add icon in the subTab then add type: 'add'
      path: '/topic/create',
    },
  ]; */

  const activeSubTabs = [];
  const hibernatedSubTabs = [];

  

  const TabScreen = {
    home: <HomePage />,
    create: <AddNewTopic />,
    view: <ActiveTopic />,
  };

  activeTopicListData && activeTopicListData?.forEach(topic => {
    TabScreen[`job_${topic.job_id}`] = <ActiveTopic job_id={topic.job_id} />;
  /*   subTabs.push({
      id: topic.job_id,
      val: topic.topic_title,
      path: `/topic/job_${topic.job_id}`,
    }); */

    const subTab = {
      id: topic.job_id,
      val: topic.topic_title,
      path: `/topic/job_${topic.job_id}`,
      channel:topic.channel
    };
    if (topic.is_active === 0) {
      activeSubTabs.push(subTab);
    } else if(topic.is_active === 1) {
      hibernatedSubTabs.push(subTab);
    }
  });

  const tabs = [
    {
      id: 1,
      val: 'Active',
      subTabs: activeSubTabs,
    },
    {
      id: 2,
      val: 'Hibernated',
      subTabs: hibernatedSubTabs,
    }
  ];

  const generateActiveTab = () => {
    if (screenId === 'home') {
      return 1;
    } else if (screenId === 'create') {
      return { tab: 1, subTab: '1a' };
    } else if (screenId === 'view') {
      return { tab: 1, subTab: '1a' };
    }
    for (let i = 0; i < activeSubTabs.length; i++) {
      if (screenId === `job_${activeSubTabs[i].id}`) {
        return { tab: 1, subTab: activeSubTabs[i].id };
      }
    }
    for (let i = 0; i < hibernatedSubTabs.length; i++) {
      if (screenId === `job_${hibernatedSubTabs[i].id}`) {
        return { tab: 2, subTab: hibernatedSubTabs[i].id };
      }
    }
  };

  return (
    <Wrapper>
      <NewSideBar
        headerName='Topic Listening'
        tabs={tabs}
        initialActiveTab={generateActiveTab()}
        buttonTitle={"New Topic"}
      />
      {TabScreen[screenId]}
    </Wrapper>
  );
};

export default TopicListener;
