// src/utils/dateUtils.js
import React from 'react';
import { useMemo } from 'react';
import { parse, format } from 'date-fns';

/**
 * Parses a date string into a valid formatted date.
 * @param {string} date - The date string to be parsed, default '28/02/2024'.
 * @param {string} formatString - The input format of the date string.
 * @param {string} formatValue - The output format of the date string.
 * @returns {string|null} - Returns the formatted date string or the original value if invalid.
 */

// Parse the date string and format it correctly
export const parseDate = (date , formatString='dd/MM/yyyy', formatValue='dd/MM/yyyy') => {
  // console.log(date, formatString, formatValue, "ssaa");  // Log inputs for debugging

  if (date && date !== "" && date != null && date != undefined) {
    // Parse the date using the correct format
    const parsedDate = typeof date === 'string' ? parse(date, formatString, new Date()) : date;

    // console.log(parsedDate, "parsedDate");  // Log parsed date for debugging

    // Check if it's a valid date
    if (!isNaN(parsedDate)) {
      // Format the date using the output formatValue
      const formattedDate = format(parsedDate, formatValue);
      // console.log(formattedDate, "formattedDate");  // Log formatted date for debugging
      return formattedDate;
    } else {
      return date; // Return the original date if invalid
    }
  }
  return null;
};


export const useParsedStartDate = (resetFlag, filteredStartDate, startDate, formatValue) => {
  return useMemo(() => {
    if (resetFlag) {
      return parseDate(filteredStartDate, 'dd/MM/yyyy', formatValue);  // Assuming input is in 'dd/MM/yyyy' format
    }
    return parseDate(startDate, 'dd/MM/yyyy', formatValue);
  }, [resetFlag, filteredStartDate, startDate, formatValue]);
};

export const useParsedEndDate = (resetFlag, filteredEndDate, endDate, formatValue) => {
  return useMemo(() => {
    if (resetFlag) {
      return parseDate(filteredEndDate, 'dd/MM/yyyy', formatValue);  // Assuming input is in 'dd/MM/yyyy' format
    }
    return parseDate(endDate, 'dd/MM/yyyy', formatValue);
  }, [resetFlag, filteredEndDate, endDate, formatValue]);
};
