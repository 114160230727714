import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as BrandAstraDarkLogo } from '../../../assests/images/LandingAssets/BrandAstraDarkLogo.svg';
import { ReactComponent as MenuIcon } from '../../../assests/images/LandingAssets/MenuIcon.svg';

import { useLocation, useNavigate } from 'react-router-dom';
import MobileNavBarMenu from './partials/MobileNavBarMenu';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '1800px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

const HeaderContainer = styled.div`
  height: 102px;
  display: flex;
  padding: 0px 120px;
  justify-content: space-between;
  @media only screen and ${device.tablet} {
    height: 60px;
    padding: 15px 24px;
  }
`;

const LogoContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;
const LogoText = styled.div`
  color: #151515;
  text-align: center;
  font-family: Inter;
  font-size: 9.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 387px;
  align-items: center;
  @media only screen and ${device.tablet} {
    display: none;
  }
`;
const OptionsText = styled.button`
  color: #15151;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
    color: var(--BrandAstra_Black, #151515);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 269px;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  @media only screen and ${device.tablet} {
    width: unset;
  }
`;
const SignInButton = styled.button`
  color: #391c99;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #391c99;
  &:hover {
    border: 2.5px solid #391c99;
    cursor: pointer;
    margin: -1.5px;
  }
  @media only screen and ${device.tablet} {
    display: none;
  }
`;
const BookaDemoButton = styled.button`
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  width: 151px;
  height: 44px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(95deg, #5937c8 0%, #391c99 100%);
  color: var(--PrimaryWhite, #faffff);
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &:hover {
    color: #fbbc05;
    cursor: pointer;
  }
  @media only screen and ${device.tablet} {
    display: none;
  }
`;
const MobileIconWrapper = styled.button`
  display: none;
  @media only screen and ${device.tablet} {
    display: flex;
    border: none;
    background: none;
  }
`;
const LandingHeader = (props) => {
  const location = useLocation();
  let navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    props?.setMenuOpen(!menuOpen);
  };

  const scrollToSection = (section) => {
    let anchor = '';
    if (location.pathname?.includes('book-a-demo')) {
      navigate('/', { state: { section } });
      setTimeout(() => {
        anchor = document.querySelector(`#${section}`);
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 300); // A
    } else {
      if (section === 'what-do-we-do') {
        anchor = document.querySelector('#what-do-we-do');
      } else if (section === 'solutions') {
        anchor = document.querySelector('#solutions');
      } else if (section === 'pricing') {
        anchor = document.querySelector('#pricing');
      } else {
        anchor = document.querySelector('#what-do-we-do');
      }
      setTimeout(() => {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300);
    }
  };
  return (
    <>
      <HeaderContainer data-testid='landing-header'>
        <LogoContainer
          onClick={() => {
            if (location.pathname?.includes('book-a-demo')) {
              navigate('/');
            }
          }}
        >
          <BrandAstraDarkLogo height={'25px'} width={'131px'} />
          <LogoText>A Copperpod Digital Product</LogoText>
        </LogoContainer>
        <OptionsContainer>
          <OptionsText
            onClick={() => {
              scrollToSection('what-do-we-do');
            }}
          >
            What do we do?
          </OptionsText>

          <OptionsText
            onClick={() => {
              scrollToSection('solutions');
            }}
          >
            Solution
          </OptionsText>
          <OptionsText onClick={() => scrollToSection('pricing')}>
            Pricing
          </OptionsText>
        </OptionsContainer>
        <ButtonContainer>
          <MobileIconWrapper onClick={toggleMenu}>
            <MenuIcon />
          </MobileIconWrapper>
          <SignInButton
            onClick={() => {
              navigate('/login');
            }}
          >
            Sign In
          </SignInButton>
          <BookaDemoButton
            onClick={() => {
              navigate('/book-a-demo');
            }}
          >
            Book a Demo
          </BookaDemoButton>
        </ButtonContainer>
      </HeaderContainer>
      {menuOpen && (
        <MobileNavBarMenu
          toggleMenu={(sectionName) => {
            scrollToSection(sectionName);
            toggleMenu();
          }}
        />
      )}
    </>
  );
};

export default LandingHeader;
