import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  HeaderWrapper,
  Span1,
  Span2,
  QueryPara,
  FlexWrapper,
  ChannelsText,
  TwitterText,
  StyledContainer,
  QueryPara2,
  TabHeading,
  Icon,
  FilterWrapper,
  DateWrapper,
  CreateTopic,
  DeletePopupWrapper,
  DivContainer,
  RadioContainer,
} from './ActiveTopic.styled';
// import SideBar from '../../components/SideBar/SideBar';
import {
  X_icon,
  filter_icon,
  share_icon,
  reset_icon,
  StyledAddIcon,
  InstagramIconSmall,
  FacebookIcon,
  FacebookLogoSmall,
  NewsLogo,
  InstagramLogo,
  CrossIconWithOutBorder,
} from '../../Common/SvgIcon/CommonSvgIcons';

import { useSelector, useDispatch } from 'react-redux';

import Sentiments from './Sentiments/SentimentsTab';
import Insights from './Insights/InsightsTab';
import ReviewsTab from './ReviewsTab/ReviewsTab';
import Overview from './OverViewTab/OverView';
import ClusterAnalysisTab from './ClusterAnalysisTab/ClusterAnalysisTab';
import CustomAnalysisTab from './CustomAnalysisTab/CustomAnalysisTab';
import { formatDate, formatDateFilter, getDate } from '../../utils';
import GenerateAnalysis from '../../components/Generate Analysis/GenerateAnalysis';

import {
  fetchCreatedTopic,
  createNewTopic,
  activeTopicList,
  FilterReportBasedOnDates,
} from '../../store/redux/slices/topicSlice';
import { useNavigate } from 'react-router-dom';
import PopUp from '../../Common/PopUp/PopUp';
import Button from '../../Common/Button/Button';
import SmallSelect from '../../Common/Select/SmallSelect';
import OverviewTabInsta from './OverViewTab/OverviewTabInsta';
import OverviewTabFb from './OverViewTab/OverviewTabFb';
import MyDatePicker from '../../components/DatePicker/DatePicker';
import { getPersonalDetails } from '../../store/redux/slices/tenantSettingSlice';
import OverviewNews from './OverViewTab/OverviewNews';
import { FlexForLogoDiv, H3TitleForInsta, InstagramCrossWrapper, InstagramImgDiv, InstaLogoWrapper, InstaTopicTitle, InstaWrapper, PTitleforInsta } from '../TopicBuilder/MainContent/QueryBuilder/TopicBuilder.styled';
import { Verified } from '@mui/icons-material';

const ActiveTopic = ({ job_id }) => {
  const [activeTab, setActiveTab] = useState('overViewTab');
  const [searchQuery, setSearchQuery] = useState('');
  const [shareOption, setShareOption] = useState(false);
  const [filterFlag, setFilterFlag] = useState(false);
  const [filterOption, setFilterOption] = useState('Bar Graph');
  const [filteredData, setFilteredData] = useState(null);
  const [resetFlag, setResetFlag] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [orgFilterOption, setOrgFilterOption] = useState('Bar Graph')
  const [orgView, setOrgView] = useState('')
  const [view, setView] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showSave, setShowSave] = useState(false);

  const [openDropdown, setOpenDropdown] = useState(null); // Manage open state for multiple dropdowns

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { topicPreviewData } = useSelector((state) => state.topicBuilder) || {};
  // const topicPreviewData = data;
  const { createdTopicData } = useSelector((state) => state.topicBuilder);
  const { personalDetailsData } = useSelector((state) => state.tenantSetting) || {};

  let fetchedData;

  // const selectedTopic = createdTopicData.find(
  //   (topic) => topic.job_id === job_id
  // );
  const selectedTopic =
    createdTopicData?.reduce((foundTopic, topic) => {
      // If a topic with the matching job_id is found, assign it to foundTopic
      if (topic.job_id == job_id) {
        return topic;
      }
      return foundTopic; // Otherwise, return the previous foundTopic value
    }, null); // Initialize foundTopic with null

  if (typeof job_id === 'number' && selectedTopic) {
    topicPreviewData = selectedTopic;
    fetchedData = selectedTopic?.data;
  } else {

    fetchedData = topicPreviewData?.data;
  }

  useEffect(() => {
    if (personalDetailsData === null) {
      dispatch(getPersonalDetails());
    }
    if ((typeof job_id === 'number') && (selectedTopic === undefined || selectedTopic === null)) {
      dispatch(fetchCreatedTopic(job_id)).then((res) => {
        fetchedData = res?.payload?.data?.data;
      });
    }
    // console.log(JSON.stringify(fetchedData))
    if (selectedTopic || topicPreviewData) {
      setStartDate((prevStartDate) => {

        const newStartDate = selectedTopic
          ? formatDateFilter(selectedTopic?.start_date)
          : topicPreviewData
            ? formatDateFilter(topicPreviewData?.start_date)
            : '2024-01-01';

        // You can now compare newStartDate with prevStartDate if needed
        return prevStartDate !== newStartDate ? newStartDate : prevStartDate;




      });
      setEndDate((prevEndDate) => {

        const newEndDate = selectedTopic
          ? formatDateFilter(selectedTopic?.end_date)
          : topicPreviewData
            ? formatDateFilter(topicPreviewData?.end_date)
            : '01-01-2024';

        // You can now compare newStartDate with prevStartDate if needed
        return prevEndDate !== newEndDate ? newEndDate : prevEndDate;




      });
    }
    setOrgFilterOption('Bar Graph');
    setOrgView('')
  }, [job_id, selectedTopic, topicPreviewData, dispatch]);


  const overView_tab = fetchedData?.overview_tab || {};
  const sentiments_tab = fetchedData?.sentiments_tab || {};
  const insights_tab = fetchedData?.insights_tab || {};
  const clusterAnalysis_tab = fetchedData?.cluster_analysis_tab || {};
  const initialStartDate = selectedTopic ? formatDateFilter(selectedTopic?.start_date) : topicPreviewData ? formatDateFilter(topicPreviewData?.start_date) : '';
  const initialEndDate = selectedTopic ? formatDateFilter(selectedTopic?.end_date) : topicPreviewData ? formatDateFilter(topicPreviewData?.end_date) : '';
  const updateSearchQuery = (query) => {
    setSearchQuery(query);
  };
  // if (selectedTopic && startDate === '' && endDate === '') {
  //   setStartDate(
  //     formatDateFilter(selectedTopic?.start_date) || '2024/01/01'
  //   );
  //   setEndDate(formatDateFilter(selectedTopic?.end_date) || '2024/01/02');
  // } else if (topicPreviewData && startDate === '' && endDate === '') {

  //   setStartDate(
  //     formatDateFilter(topicPreviewData?.start_date) || '2024/01/01'
  //   );
  //   setEndDate(formatDateFilter(topicPreviewData?.end_date) || '2024/01/02');
  // }

  if (startDate === undefined) {
    console.log('date is undefined');
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFilter = () => {
    setApplyFilter(false)
    setFilterFlag(!filterFlag);
  };

  const handleClosePopup = () => {
    setFilterFlag(false);
  };

  const handleShareClick = () => {
    setShareOption(!shareOption);
  };

  const handleApply = () => {
    setApplyFilter(true)
    setFilterFlag(!filterFlag);
    setOrgFilterOption(filterOption)
    setOrgView(view)
  };

  const hadleFilterChange = (selectedOption) => {
    setFilterOption(selectedOption);
  };

  const handleReset = () => {
    setStartDate((prevStartDate) => {

      const newStartDate = selectedTopic
        ? formatDateFilter(selectedTopic?.initial_start_date)
        : topicPreviewData
          ? formatDateFilter(topicPreviewData?.initial_start_date)
          : '2024-01-01';

      // You can now compare newStartDate with prevStartDate if needed
      return prevStartDate !== newStartDate ? newStartDate : prevStartDate;




    });
    setEndDate((prevEndDate) => {

      const newEndDate = selectedTopic
        ? formatDateFilter(selectedTopic?.initial_end_date)
        : topicPreviewData
          ? formatDateFilter(topicPreviewData?.initial_end_date)
          : '01-01-2024';

      // You can now compare newStartDate with prevStartDate if needed
      return prevEndDate !== newEndDate ? newEndDate : prevEndDate;




    });
    // setStartDate(initialStartDate);
    // setEndDate(initialEndDate);
    setResetFlag(false);
    setFilteredData(null)
  };

  const dateHandler = (e) => {
    const { name, value } = e;
    setShowSave(true)
    const formatDate = (isoString) => {
      const date = new Date(isoString); // Create a Date object from the ISO string
      const year = date.getFullYear(); // Get the year
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-based index, hence +1)
      const day = String(date.getDate()).padStart(2, '0'); // Get the day

      return `${year}-${month}-${day}`; // Return the formatted date
    };


    const formattedDate = formatDate(value);
    if (name === 'firstPostDate') {
      setStartDate(formattedDate);
    } else if (name === 'lastPostDate') {
      setEndDate(formattedDate);
      // dispatch(FilterReportBasedOnDates({ startDate, endDate: formattedDate, job_id }))
      //   .then((res) => {
      //     setFilteredData(res.payload.response);
      //   })
      //   .catch((error) => {
      //     console.error('Error:', error); // Handling any errors
      //   });
    }
    // setResetFlag(true);
  };

  const dateSubmit = () => {
    setFilteredData(null)

    setResetFlag(true);
    const isValidDate = (date) => {
      return date instanceof Date && !isNaN(date.getTime());
    };

    const validateDate = (date) => {
      // Convert date strings to Date objects
      const start = new Date(initialStartDate); // Explicitly parse the initialStartDate
      const end = new Date(initialEndDate);     // Explicitly parse the initialEndDate
      const datedate = new Date(date);          // Explicitly parse the date to be validated

      if (!isValidDate(start) || !isValidDate(end) || !isValidDate(datedate)) return false;

      const isAfterMin = datedate >= start;
      const isBeforeMax = datedate <= end;

      // console.log("Start Date Range:", start);
      // console.log("End Date Range:", end);
      // console.log("Date Being Validated:", datedate);

      return isAfterMin && isBeforeMax;
    };

    // console.log(validateDate(startDate) , validateDate(endDate) ,initialStartDate,initialEndDate, startDate,endDate);

    if (validateDate(startDate) && validateDate(endDate)) {
      dispatch(
        FilterReportBasedOnDates({ startDate, endDate: endDate, job_id })
      )
        .then((res) => {
          setFilteredData(res.payload?.response);
          setShowSave(false)
          // setReset(false);
        })
        .catch((error) => {
          console.error('Error:', error); // Handling any errors
        });
    } else {
      alert(`Enter valid Input between ${initialStartDate} and ${initialEndDate}`)
    }

  }

  const transformText = (inputText) => {
    if (inputText == 'twitter') {
      return ' ';
    }
    const words = inputText?.split(' ');
    const transformedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));
    return transformedWords?.join(' ')
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case 'overViewTab':
        return (
          topicPreviewData?.channel === 'news' ?
            <OverviewNews
              overview_tab={overView_tab}
              startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
              filteredStartDate={startDate}
              filteredEndDate={endDate}
              endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
              filterOption={orgFilterOption}
              filteredData={filteredData?.data?.overview_tab}
              resetFlag={resetFlag}
              view={orgView}
              formatValue={
                formatValue
              }
            /> :
            topicPreviewData?.channel === 'twitter' ?
              <Overview
                overview_tab={overView_tab}
                startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
                filteredStartDate={startDate}
                filteredEndDate={endDate}
                endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
                filterOption={orgFilterOption}
                filteredData={filteredData?.data?.overview_tab}
                resetFlag={resetFlag}
                view={orgView}
                formatValue={
                  formatValue
                }
              />
              :
              topicPreviewData?.channel === 'facebook' ?
                <OverviewTabFb
                  overview_tab={overView_tab}
                  startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
                  filteredStartDate={startDate}
                  filteredEndDate={endDate}
                  endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
                  filterOption={orgFilterOption}
                  filteredData={filteredData?.data?.overview_tab}
                  resetFlag={resetFlag}
                  view={orgView}
                  formatValue={
                    formatValue
                  }
                />
                :
                <OverviewTabInsta
                  overview_tab={overView_tab}
                  startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
                  filteredStartDate={startDate}
                  filteredEndDate={endDate}
                  endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
                  filterOption={orgFilterOption}
                  filteredData={filteredData?.data?.overview_tab}
                  resetFlag={resetFlag}
                  view={orgView}
                  formatValue={
                    formatValue
                  }
                />
        );
      case 'sentimentsTab':
        return (
          <Sentiments
            sentiments_tab={sentiments_tab}
            startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
            endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            filterOption={orgFilterOption}
            resetFlag={resetFlag}
            filteredData={filteredData?.data?.sentiments_tab}
            view={orgView}
            channel={topicPreviewData?.channel}
            formatValue={
              formatValue
            }
            is_news={topicPreviewData?.channel === 'news' ? true : false}
          />
        );
      case 'insightTab':
        return (
          <Insights
            insights_data={insights_tab}
            startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
            endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            filterOption={orgFilterOption}
            filteredData={filteredData?.data?.insights_tab}
            search_type={topicPreviewData?.search_type || ''}
            view={orgView}
            resetFlag={resetFlag}
            channel={topicPreviewData?.channel}
            formatValue={
              formatValue
            }
            is_news={topicPreviewData?.channel === 'news' ? true : false}
          />
        );
      case 'clusterAnalysis':
        return (
          <ClusterAnalysisTab
            cluster_analysis={clusterAnalysis_tab}
            startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
            endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
            filteredData={filteredData?.data?.cluster_analysis}
            resetFlag={resetFlag}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            channel={topicPreviewData?.channel}
            job_id={job_id}
            formatValue={
              formatValue
            }
          />
        );
      case 'customAnalysis':
        return (
          <CustomAnalysisTab
            job_id={job_id}
            preview_uuid={topicPreviewData?.preview_uuid}
            onUpdateSearchQuery={updateSearchQuery}
            channel={topicPreviewData?.channel}
          />
        );
      case 'reviewsTab':
        return (
          <ReviewsTab
            min={initialStartDate}
            max={initialEndDate}
            startDate={startDate || initialStartDate}
            search_type={topicPreviewData?.search_type || ''}
            endDate={endDate || initialEndDate}
            // startDate={selectedTopic?.start_date || topicPreviewData?.start_date}
            // endDate={selectedTopic?.end_date || topicPreviewData?.end_date}
            // startDate={startDate}
            // endDate={endDate}6
            preview_uuid={topicPreviewData?.preview_uuid}
            job_id={topicPreviewData?.job_id}
            filteredReviews={filteredData}
            channel={topicPreviewData?.channel}
            formatValue={
              formatValue
            }
          />
        );
      default:
        return null;
    }
  };

  const handleClear = () => {
    setOrgFilterOption('Bar Graph');
    setOrgView(null);
  };
  // console.log(topicPreviewData,"topicPreviewData");

  const handleCreatedTopic = () => {
    dispatch(createNewTopic(topicPreviewData?.inputData))
      .then((res) => {
        dispatch(activeTopicList());
        if (res.payload?.data?.job_id) {
          dispatch(fetchCreatedTopic(res.payload?.data?.job_id)).then((res) => {
            fetchedData = res?.payload?.data?.data;
          });

          navigate('/topic/job_' + res?.payload?.data?.job_id);
        } else {

          // navigate('/topic/home');
        }


      })
      .catch((err) => console.log(err))
  };

  const formatValue = personalDetailsData?.app_preference?.date_format === "dd/mm/yyyy" ? 'dd-MM-yyyy' :
    personalDetailsData?.app_preference?.date_format === "mm/dd/yyyy" ? 'MM-dd-yyyy' :
      personalDetailsData?.app_preference?.date_format === "yyyy/mm/dd" ? 'yyyy-MM-dd' :
        'dd-MM-yyyy';

  // console.log(startDate || initialStartDate,"sstartDate || initialStartDate");
  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      // Billion
      return (num / 1_000_000_000).toFixed(1) + 'B';
    } else if (num >= 1_000_000) {
      // Million
      return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      // Thousand
      return (num / 1000).toFixed(1) + 'k';
    } else {
      return num; // Return the number as is if it's less than 1000
    }
  }
console.log(topicPreviewData,"topicPreviewData");

  return (
    <Wrapper>
      {shareOption && (
        <GenerateAnalysis
          open={shareOption}
          onClose={() => setShareOption(false)}
          moduleType={topicPreviewData?.channel == 'news' ? 'News' : topicPreviewData?.channel == 'facebook' ? 'Facebook' : topicPreviewData?.channel == 'instagram' ? 'Instagram' : 'Twitter'}
          title={topicPreviewData?.title}
          job_id={job_id}
          /* searchQuery = {searchQuery} */
          reportData={fetchedData}
          topicPreviewData={topicPreviewData}
        /*  imageResponse = {localStorage.getItem('imageResponse')}
         tableResponse = {localStorage.getItem('tableData')}  */
        />
      )}
      {filterFlag && (
        <PopUp onClose={handleClosePopup}>
          <DeletePopupWrapper>
            <p>All Filters</p>
            <DivContainer>
              <SmallSelect
                option={filterOption}
                options={[
                  'Bar Graph',
                  'Donut Chart',
                  'Pie Chart',
                  'Funnel Chart',
                ]}
                onChange={(selectedOption) => hadleFilterChange(selectedOption)}
                placeholder='Visual View'
                
                dropdownId="fliters"
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
              />
              {
                filterOption == 'Bar Graph' &&
                <RadioContainer>
                  <input
                    type='radio'
                    name='viewType'
                    value='horizontal'
                    checked={view === 'horizontal'}
                    onChange={(e) => setView(e.target.value)}
                  />{' '}
                  Horizontal View
                  <input
                    type='radio'
                    name='viewType'
                    value='vertical'
                    checked={view === 'vertical'}
                    onChange={(e) => setView(e.target.value)}
                  />{' '}
                  Vertical View
                </RadioContainer>
              }

            </DivContainer>
            <div>
              <Button value={'Cancel'} onClick={() => setFilterFlag(false)} />
              <Button value={'Clear All'} onClick={handleClear} />
              <Button value={'Apply'} onClick={handleApply} />
            </div>
          </DeletePopupWrapper>
        </PopUp>
      )}
      <HeaderWrapper>
        <Span1>{transformText(topicPreviewData?.title)}</Span1>
        <Span2 style={{ width: typeof job_id !== 'number' ? '80%' : '100%' }}>{topicPreviewData?.description}</Span2>
        <StyledContainer>
          <ChannelsText>Channel:</ChannelsText>
          <TwitterText>
            {/* <Icon>{topicPreviewData?.channel === 'news' ? NewsLogo : topicPreviewData?.channel === 'twitter' ? X_icon : topicPreviewData?.channel === 'instagram' ? InstagramIconSmall : topicPreviewData?.channel === 'facebook' ? FacebookLogoSmall : ''}</Icon>
            {transformText(topicPreviewData?.channel)} */}
            <Icon>{topicPreviewData?.channel === 'twitter' ? X_icon : topicPreviewData?.channel === 'news' ? NewsLogo : topicPreviewData?.channel === 'instagram' ? InstagramIconSmall : topicPreviewData?.channel === 'facebook' ? FacebookLogoSmall : ''}</Icon>
            {topicPreviewData?.channel == 'twitter' ? '' : transformText(topicPreviewData?.channel)}
          </TwitterText>
        </StyledContainer>
        {
          topicPreviewData?.channel == 'instagram' && topicPreviewData?.search_type == 'hashtag' ?
            <div>
              <FlexForLogoDiv>
                <InstagramImgDiv>
                  {/* {
                  instaDetails?.[0]?.type == "Account" ?
                    <image src="" alt='drc' style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
          
                    : */}

                  <div style={{ width: '70px', borderRadius: '50%', fontSize: '50px', textAlign: 'center' }}>#</div>
                  {/* } */}
                </InstagramImgDiv>
                <div>
                  
                <H3TitleForInsta>{topicPreviewData?.user_name && `#${topicPreviewData?.user_name}` || ''}</H3TitleForInsta>
                  <PTitleforInsta>{`${formatNumber(topicPreviewData?.engagement)} posts` || ''}</PTitleforInsta>
                  
                  {/* <H3TitleForInsta>#ipstorelombok</H3TitleForInsta>
                  <PTitleforInsta>298k posts</PTitleforInsta> */}


                  {/* <H3TitleForInsta>{instaDetails?.[0]?.name || instaDetails?.[0]?.user_name}</H3TitleForInsta>
                <PTitleforInsta>{instaDetails?.[0]?.full_name}</PTitleforInsta>
                <PTitleforInsta>{instaDetails?.[0]?.posts_count && `${instaDetails?.[0]?.posts_count} posts` || instaDetails?.[0]?.followers_count && `${instaDetails?.[0]?.followers_count} followers`}</PTitleforInsta> */}


                  {/* <H3TitleForInsta>{instaDetails?.[0]?.name || instaDetails?.[0]?.user_name}</H3TitleForInsta>
                <PTitleforInsta>{instaDetails?.[0]?.full_name}</PTitleforInsta>
                <PTitleforInsta>{instaDetails?.[0]?.posts_count && `${instaDetails?.[0]?.posts_count} posts` || instaDetails?.[0]?.followers_count && `${instaDetails?.[0]?.followers_count} followers`}</PTitleforInsta> */}
                </div>
              </FlexForLogoDiv>

            </div>
            :
            topicPreviewData?.channel == 'instagram' && topicPreviewData?.search_type == 'user' ?
            <div>
              <FlexForLogoDiv>
                <InstagramImgDiv>
                  {/* {
                  instaDetails?.[0]?.type == "Account" ?
                    <image src="" alt='drc' style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
          
                    : */}

                  {/* <div style={{ width: '70px', borderRadius: '50%', fontSize: '50px', textAlign: 'center' }}>#</div> */}
                  {/* } */}
                  <img src={topicPreviewData?.profile_pic_url || ''} alt='drc' style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
          
                </InstagramImgDiv>
                <div>
                <H3TitleForInsta>{topicPreviewData?.user_name || decodeURIComponent(topicPreviewData?.user_name || '')}
                    <span style={{ color: 'rgb(25, 118, 210)', height: '23px', marginLeft: '8px' }}>
                      {/*  */}
                      {topicPreviewData?.search_type == "user" && topicPreviewData?.verified == true && <Verified />}
                    </span></H3TitleForInsta>
                  {/* <H3TitleForInsta>{topicPreviewData?.user_name || ''}</H3TitleForInsta> */}
                  <PTitleforInsta>{topicPreviewData?.full_name || ''}</PTitleforInsta>
                  <PTitleforInsta>
                  {
                  topicPreviewData?.search_type == "user" ?
                   `${formatNumber(topicPreviewData?.engagement)} followers` 
                   : topicPreviewData?.search_type == "hashtag"  ? 
                   `${formatNumber(topicPreviewData?.engagement)} posts` :
                    ''}
                    {/* {topicPreviewData?.engagement || ''} */}
                    </PTitleforInsta>


                  {/* <H3TitleForInsta>{instaDetails?.[0]?.name || instaDetails?.[0]?.user_name}</H3TitleForInsta>
                <PTitleforInsta>{instaDetails?.[0]?.full_name}</PTitleforInsta>
                <PTitleforInsta>{instaDetails?.[0]?.posts_count && `${instaDetails?.[0]?.posts_count} posts` || instaDetails?.[0]?.followers_count && `${instaDetails?.[0]?.followers_count} followers`}</PTitleforInsta> */}


                  {/* <H3TitleForInsta>{instaDetails?.[0]?.name || instaDetails?.[0]?.user_name}</H3TitleForInsta>
                <PTitleforInsta>{instaDetails?.[0]?.full_name}</PTitleforInsta>
                <PTitleforInsta>{instaDetails?.[0]?.posts_count && `${instaDetails?.[0]?.posts_count} posts` || instaDetails?.[0]?.followers_count && `${instaDetails?.[0]?.followers_count} followers`}</PTitleforInsta> */}
                </div>
              </FlexForLogoDiv>

            </div>
            :
            <QueryPara>
              <b>Query Used:&nbsp;</b> <span style={{ cursor: topicPreviewData?.channel == 'instagram' ? 'pointer' : '' }}>{topicPreviewData?.keywords_string}</span>
              <br />
              {/* <br /> lang: en since: 2023-01-01 until: 2023-05-18 geocode: 20.5937,78.9629,2000 km */}
            </QueryPara>
        }
        {typeof job_id !== 'number' && (
          <CreateTopic onClick={handleCreatedTopic}>
            Create Topic
            {StyledAddIcon}
          </CreateTopic>
        )}
      </HeaderWrapper>
      <FilterWrapper>
        <DateWrapper>
          <div>
            <div>First Post Date:</div>
            {/* <input
              type='date'
              name='firstPostDate'
              value={startDate || initialStartDate}
              onChange={(e) => dateHandler(e)}
              min={initialStartDate}
              max={initialEndDate}
            /> */}
            <MyDatePicker
             
                width='110px'
              name='firstPostDate'
              value={startDate || initialStartDate}
              dateHandler={dateHandler}
              format={
                formatValue
              }
              min={initialStartDate}
              max={initialEndDate}
            />
          </div>
          <div>
            <div>Last Post Date:</div>
            {/* <input
              type='date'
              name='lastPostDate'
              value={endDate || initialEndDate}
              onChange={(e) => dateHandler(e)}
              min={startDate}
              max={initialEndDate}
            /> */}
            <MyDatePicker
            
                width='110px'
              name='lastPostDate'
              value={endDate || initialEndDate}
              dateHandler={dateHandler}
              format={
                formatValue
              }
              min={startDate}
              max={initialEndDate}
            />
          </div>
          {typeof job_id === 'number' && (showSave ?
            (
              <button className='button' onClick={dateSubmit}>
                Save
              </button>
            )
            :
            (
              <button className='button' onClick={handleReset}>
                Reset
                {reset_icon}
              </button>
            )
          )
          }
        </DateWrapper>
        <div className='buttonContainer'>
          <button className='button' onClick={handleFilter}>
            Filters
            {filter_icon}
          </button>
          {typeof job_id === 'number' && (
            <button className='button' onClick={handleShareClick}>
              Share
              {share_icon}
            </button>
          )}
        </div>
      </FilterWrapper>
      <TabHeading>
        <div
          className={activeTab === 'overViewTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('overViewTab')}
        >
          Overviews
        </div>
        <div
          className={activeTab === 'sentimentsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('sentimentsTab')}
        >
          Sentiments
        </div>
        <div
          className={activeTab === 'insightTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('insightTab')}
        >
          Insights
        </div>
        <div
          className={activeTab === 'clusterAnalysis' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('clusterAnalysis')}
        >
          Cluster Analysis
        </div>
        <div
          className={activeTab === 'customAnalysis' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('customAnalysis')}
        >
          Custom Analysis
        </div>
        <div
          className={activeTab === 'reviewsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('reviewsTab')}
        >
          Posts
        </div>
      </TabHeading>
      {renderTabContent()}
    </Wrapper>
  );
};

export default ActiveTopic;
