import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CrossIcon, NewsIcon, TwitterIcon, TwitterLogoX, X_icon, X_LOGO } from '../../../../Common/SvgIcon/CommonSvgIcons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  FacebookIcon,
  Instagram,
  QuoraIcon,
  RedditIcon,
  Shape,
  Twitter,
  YoutubeIcon,
} from '../../../../Common/SvgIcon/CommonSvgIcons';

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 15px 21px;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #a2a2a2;

  & .placeholder {
    color: rgba(99, 99, 99, 0.7);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
  }

  & .message {
    color: #000;
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 400;
  }
`;

const StyledDownArrow = styled(KeyboardArrowDownIcon)`
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
`;

const ExpandedChannelWrapper = styled.div``;
const ChannelsWrapper = styled.div`
  display: flex;
  gap: 20px 10px;
  flex-wrap: wrap;
  padding: 20px 0;
  box-sizing: border-box;
`;
const Channel = styled.div`
  flex: 1 0 calc(33.33% - 10px);
  border-radius: 10px;
  box-sizing: border-box;
  background: #f2f2fe;
  max-width:160px;

  & > div:first-child {
    padding: 7px 20px;
    gap: 7px;
    border-radius: 10px;
    background: #353cf7;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;

    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
  }
  & > div:last-child {
    cursor: pointer;
    margin: 20px auto;
    width: 56%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px 12px;
    border-radius: 10px;
    background: #fff;

    box-shadow: 0px 0px 3px 0.1px #a2a2a2;

    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    color: #000;

    & .selected {
      color: #353cf7;
    }
  }
`;

const SelectedChannels = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: #f2f2fe;
  padding: 3px 8px;
  border-radius: 10px;

  color: #2a2929;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;

  flex: 1 0 calc(33.33% - 10px);
  max-width: min-content;

  & > span {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

const ConnectChannels = ({ showSecondModule, handleConnectChannelsToggle, channel, error, isai = false }) => {
  const [expanded, setExpanded] = useState(false);
  const [allChannels, setAllChannels] = useState([]);
  const connectChannelsRef = useRef(null);
  const handleChannelLayout = (val) => {
    console.log("dddhhhh",val);

    setExpanded(val);
    console.log("dddhhhh",!val);
  };


  useEffect(() => {
    const handleOutsideClick = (event) => {
      console.log("OhandleOutsideClick",connectChannelsRef.current &&
        !connectChannelsRef.current.contains(event.target));
      
      if (
        connectChannelsRef.current &&
        !connectChannelsRef.current.contains(event.target) &&
        !event.target.classList.contains('placeholder')
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };


  }, []);

  useEffect(() => {
    let channel = [];
    if (isai) {
      channel = [{
        id: 1,
        channelName: 'twitter',
        icon: TwitterLogoX,
      }]

    } else {
      channel = [
        {
          id: 1,
          channelName: 'twitter',
          icon: TwitterLogoX,
        },
        {
          id: 2,
          channelName: 'instagram',
          icon: Instagram,
        },
        // {
        //   id: 3,
        //   channelName: 'YouTube',
        //   icon: YoutubeIcon,
        // },
        {
          id: 3,
          channelName: 'facebook',
          icon: FacebookIcon,
        },
        // {
        //   id: 4,
        //   channelName: 'news',
        //   icon: NewsIcon,
        // }
        // {
        //   id: 5,
        //   channelName: 'Reddit',
        //   icon: RedditIcon,
        // },
        // {
        //   id: 6,
        //   channelName: 'Quora',
        //   icon: QuoraIcon,
        // },
      ]

    }
    // console.log(channel,isai);


    setAllChannels((prev) => {
      return channel
    })
  }, [isai])



  return (
    <InputWrapper data-testid="DivSelectChannel" ref={connectChannelsRef} onClick={() => handleChannelLayout(!expanded)} >
      {expanded && !showSecondModule && (
        <ExpandedChannelWrapper>
          <span className='message'>
            Seamlessly access and interact with your preferred platform
          </span>
          <ChannelsWrapper>
            {allChannels.map((el) => (
              <Channel key={el.id}>
                <div>
                  <span>{el.icon} </span>
                  {el?.channelName && (el?.channelName == 'twitter' ? ' ' : el.channelName.charAt(0).toUpperCase() + el.channelName.slice(1))}
                </div>
                <div
                data-testid="handleConnectChannelsToggleDiv"
                  onClick={() => {
                    handleConnectChannelsToggle(el.channelName)
                    setExpanded()
                  }}
                >
                  {channel === el.channelName ? (
                    <>
                      <span className='selected'>{Shape} Selected</span>
                    </>
                  ) : (
                    'Connect'
                  )}
                </div>
              </Channel>
            ))}
          </ChannelsWrapper>
        </ExpandedChannelWrapper>
      )}
      {!expanded && !channel && (
        <p className='placeholder' style={{margin:'0px',cursor:'pointer'}}>Select preferred channel</p>
      )}
      {
        expanded && !channel && showSecondModule && (
          <p className='placeholder' style={{margin:'0px',cursor:'pointer'}} >Select preferred channel</p>
        )
      }
      {!expanded && channel && (
        <>
          {/*   {channel.map((el) => (
            <SelectedChannels key={el}>
              {el}
              <span onClick={() => handleConnectChannelsToggle(el)}>
                {CrossIcon}
              </span>
            </SelectedChannels>
          ))} */}
          <>
            <SelectedChannels>
              {channel && (channel == 'twitter' ? X_LOGO : channel.charAt(0).toUpperCase() + channel.slice(1))}

              {/* {channel} */}
              <span onClick={() => handleConnectChannelsToggle(channel)}>
                {CrossIcon}
              </span>
            </SelectedChannels>
          </>
        </>
      )}
      <StyledDownArrow style={{ color: showSecondModule ? 'rgb(209, 201, 201)' : 'black' }} />
    </InputWrapper>
  );
};

export default ConnectChannels;
