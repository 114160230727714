import React from 'react';
import DonutChart from '../../../../../Common/SentimentCharts/SentimentCharts';
import CorrelationGraph from '../../../../../components/CorrelationGraph/CorrelationGraph';
import BarGraph from '../../../../../Common/BarGraph/BarGraph';
import FunnelGraph from '../../../../../Common/FunnelGraph/FunnelGraph';
import RatingDonutChart from '../../../../../Common/RatingDonutChart/DonutChart';
import {
    emoji_anger,
    emoji_disgust,
    emoji_fear,
    emoji_neutral,
    emoji_sadness,
    emoji_surprise,
    emoji_joy,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';
import VerticalBarGraph from '../../../../../Common/BarGraph/VarticalBargraph';
import {
    Wrapper,
    PieWrapper,
    HeadingSpan,
    EmotionAnalysisWrapper,
    FilteredGraphContainer,
    MapContainer,
    Container1
} from './InsightsTab.styled.js'
import WordCloudAnalysis from '../WordCloudAnalysis.jsx';
import GraphHOC from '../GraphHOC.jsx';
import MapTableView from '../../../../../components/Maps/MapsTableView.jsx';
import { useParsedEndDate, useParsedStartDate } from '../../../../../utils/ParseDate.jsx';
import TitleWithInfo from '../../../../../Common/TitleWithInfo/TitleWithInfo.jsx';
import { Span } from '../../../ChatterReport/OverView.styled.js';

function InsightsTab({
    insightTabData,
    plan,
    selectedReport,
    filteredData,
    filterOption,
    view,
    reset,
    filteredStartDate,
    filteredEndDate,

    startDate = '',
    endDate = '',
    formatValue,
    child,
    hideDropdown
}) {

    const formattedDate = useParsedStartDate(false, '', startDate, formatValue);
    const endDateParsed = useParsedEndDate(false, '', endDate, formatValue);

    let planSpecificData;
    if (selectedReport) {
        if (plan === 'BrandAstraPro') {
            planSpecificData = selectedReport?.data;
        } else if (plan === 'BrandEssence') {
            // planSpecificData = selectedReport?.BrandAstraEssence;
            planSpecificData = selectedReport?.data;
        } else if (plan === 'BrandAstraElite') {
            planSpecificData = selectedReport?.BrandAstraElite;
        } else {
            planSpecificData = selectedReport?.data;

        }
    } else if (insightTabData) {
        if (plan === 'BrandAstraPro') {
            planSpecificData = insightTabData?.data;
        } else if (plan === 'BrandEssence') {

            planSpecificData = insightTabData?.data;
            // planSpecificData = insightTabData?.BrandAstraEssence;
        } else if (plan === 'BrandAstraElite') {
            planSpecificData = insightTabData?.BrandAstraElite;
        } else {
            planSpecificData = insightTabData?.data;

        }
    }

    const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

    const COLOR_RANGE_1 = [
        '#C4C4CD',
        '#87D3F2',
        '#4EBEEB',
        '#35B8E8',
        '#188CE6',
        '#1777CF',
        '#155CB4',
    ];
    const COLOR_RANGE_2 = [
        '#C4C4CD',
        '#57E188',
        '#2DB757',
        '#36C768',
        '#189D3E',
        '#168736',
    ];
    const COLOR_RANGE_3 = [
        '#C4C4CD',
        '#F9FFA1',
        '#FFF060',
        '#F2DB00',
        '#E1B602',
        '#E7A300',
    ];
    const COLOR_RANGE_4 = [
        '#C4C4CD',
        '#FF9A91',
        '#F95D54',
        '#FF4237',
        '#F4362C',
        '#B9251C',
    ];

    // let emotionData;

    // if (
    //     insightTabData?.channel === 'amazon' ||
    //     selectedReport?.channel === 'amazon'
    // ) {
        const emotionData = [
            {
                name: 'Neutral',
                value: filteredData
                    ? filteredData?.insights_tab?.emotion_analysis[0]?.neutral
                    : planSpecificData?.insights_tab?.emotion_analysis[0]?.neutral,
                // fill: '#F5CC61',
                fill: 'rgba(251, 214, 121, 1)'
            },
            {
                name: 'Surprise',
                value: filteredData
                    ? filteredData?.insights_tab?.emotion_analysis[0]?.surprise
                    : planSpecificData?.insights_tab?.emotion_analysis[0]?.surprise,
                // fill: '#B7DC68',
                fill: 'rgba(3, 198, 82, 1)'
            },
            {
                name: 'joy',
                value: filteredData
                    ? filteredData?.insights_tab?.emotion_analysis[0]?.joy
                    : planSpecificData?.insights_tab?.emotion_analysis[0]?.joy,
                // fill: '#FE00D5',
                fill: 'rgba(218, 6, 184, 1)'
            },
            {
                name: 'Fear',
                value: filteredData
                    ? filteredData?.insights_tab?.emotion_analysis[0]?.fear
                    : planSpecificData?.insights_tab?.emotion_analysis[0]?.fear,
                // fill: '#FC9E58',
                fill: 'rgba(255, 153, 0, 1)'
            },
            {
                name: 'Sadness',
                value: filteredData
                    ? filteredData?.insights_tab?.emotion_analysis[0]?.sadness
                    : planSpecificData?.insights_tab?.emotion_analysis[0]?.sadness,
                // fill: '#4B9AE9',
                fill: 'rgba(21, 112, 203, 1)'
            },
            {
                name: 'Anger',
                value: filteredData
                    ? filteredData?.insights_tab?.emotion_analysis[0]?.anger
                    : planSpecificData?.insights_tab?.emotion_analysis[0]?.anger,
                // fill: '#F74945',
                fill: 'rgba(238, 19, 7, 1)'
            },
            {
                name: 'Disgust',
                value: filteredData
                    ? filteredData?.insights_tab?.emotion_analysis[0]?.disgust
                    : planSpecificData?.insights_tab?.emotion_analysis[0]?.disgust,
                // fill: '#9071CE',
                fill: 'rgba(84, 30, 192, 1)'
            },
        ];
    // } else {
    //     emotionData = [
    //         {
    //             name: 'Neutral',
    //             value: filteredData
    //                 ? filteredData?.insights?.emotion_analysis[0]?.neutral
    //                 : planSpecificData?.insights_tab?.emotion_analysis[0]?.neutral,
    //             fill: '#F5CC61',
    //         },
    //         {
    //             name: 'Surprise',
    //             value: filteredData
    //                 ? filteredData?.insights?.emotion_analysis[0]?.surprise
    //                 : planSpecificData?.insights_tab?.emotion_analysis[0]?.surprise,
    //             fill: '#B7DC68',
    //         },
    //         {
    //             name: 'joy',
    //             value: filteredData
    //                 ? filteredData?.insights?.emotion_analysis[0]?.joy
    //                 : planSpecificData?.insights_tab?.emotion_analysis[0]?.joy,
    //             fill: '#FE00D5',
    //         },
    //         {
    //             name: 'Fear',
    //             value: filteredData
    //                 ? filteredData?.insights?.emotion_analysis[0]?.fear
    //                 : planSpecificData?.insights_tab?.emotion_analysis[0]?.fear,
    //             fill: '#FC9E58',
    //         },
    //         {
    //             name: 'Sadness',
    //             value: filteredData
    //                 ? filteredData?.insights?.emotion_analysis[0]?.sadness
    //                 : planSpecificData?.insights_tab?.emotion_analysis[0]?.sadness,
    //             fill: '#4B9AE9',
    //         },
    //         {
    //             name: 'Anger',
    //             value: filteredData
    //                 ? filteredData?.insights?.emotion_analysis[0]?.anger
    //                 : planSpecificData?.insights_tab?.emotion_analysis[0]?.anger,
    //             fill: '#F74945',
    //         },
    //         {
    //             name: 'Disgust',
    //             value: filteredData
    //                 ? filteredData?.insights?.emotion_analysis[0]?.disgust
    //                 : planSpecificData?.insights_tab?.emotion_analysis[0]?.disgust,
    //             fill: '#9071CE',
    //         },
    //     ];
    // }

    const renderScreen = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph
                    data={emotionData}
                    maxWidth={'70%'}
                    type={'%'}
                    barHeight={'32px'}
                    emojiList={{
                        neutral: emoji_neutral,
                        surprise: emoji_surprise,
                        joy: emoji_joy,
                        fear: emoji_fear,
                        sadness: emoji_sadness,
                        anger: emoji_anger,
                        disgust: emoji_disgust,
                        length: 2,
                    }}
                />
            ) : (
                <BarGraph
                    data={emotionData}
                    maxWidth={'70%'}
                    type={'%'}
                    barHeight={'32px'}
                    emojiList={{
                        neutral: emoji_neutral,
                        surprise: emoji_surprise,
                        joy: emoji_joy,
                        fear: emoji_fear,
                        sadness: emoji_sadness,
                        anger: emoji_anger,
                        disgust: emoji_disgust,
                        length: 2,
                    }}
                />
            ),
        'Pie Chart': <RatingDonutChart data={emotionData} type='percentage' />,
        'Donut Chart': <DonutChart data={emotionData} type='percentage' />,
        'Funnel Chart': (
            <FunnelGraph data={emotionData} maxWidth={'80%'} type={'%'} />
        ),
    };

    const mapData = planSpecificData?.insights_tab?.map_data?.reviews_by_state;
    const highestReviewsState = mapData && Math.max(
        ...Object.values(mapData)
    );
    const positiveMapData =
        planSpecificData?.insights_tab?.map_data?.positive_reviews_by_state;
    const negativeMapData =
        planSpecificData?.insights_tab?.map_data?.positive_reviews_by_state;
    const neutralMapData =
        planSpecificData?.insights_tab?.map_data?.positive_reviews_by_state;

    let stateWithHighestReviews = "";
    let highestReviews = 0;
    let stateWitPositiveReview = '';
    let stateWithNegativeReview = '';
    let stateWithNeutralReview = '';
    if (mapData && typeof mapData === 'object' && Object.keys(mapData)?.length > 0) {

        [stateWithHighestReviews, highestReviews] = mapData && Object.entries(mapData).reduce((maxPair, currentPair) => {
            return currentPair[1] > maxPair[1] ? currentPair : maxPair;
        });
    } else {
        stateWithHighestReviews = null;
        highestReviews = null;
    }
    if (positiveMapData && typeof positiveMapData === 'object' && Object.keys(positiveMapData)?.length > 0) {

        [stateWitPositiveReview, highestReviews] = mapData && Object.entries(positiveMapData).reduce((maxPair, currentPair) => {
            return currentPair[1] > maxPair[1] ? currentPair : maxPair;
        });
    } else {
        stateWitPositiveReview = null;
    }

    if (negativeMapData && typeof negativeMapData === 'object' && Object.keys(negativeMapData)?.length > 0) {

        [stateWithNegativeReview] = Object.entries(negativeMapData).reduce((maxPair, currentPair) => {
            return currentPair[1] > maxPair[1] ? currentPair : maxPair;
        });
    } else {
        stateWithNegativeReview = null;
    }

    if (neutralMapData && typeof neutralMapData === 'object' && Object.keys(neutralMapData)?.length > 0) {

        [stateWithNeutralReview] = Object.entries(neutralMapData).reduce((maxPair, currentPair) => {
            return currentPair[1] > maxPair[1] ? currentPair : maxPair;
        });
    } else {
        stateWithNeutralReview = null;
    }

    const emotionAnalysisGraph = renderScreen[filterOption];
    let reviewhelpfullnessData;
    if (
        insightTabData?.channel === 'flipkart' ||
        selectedReport?.channel === 'flipkart'
    ) {
        reviewhelpfullnessData = planSpecificData?.insights_tab?.review_liked;
    } else {
        reviewhelpfullnessData = filteredData
            ? filteredData?.sentiments?.review_helpfullness
            : planSpecificData?.insights_tab?.review_helpfullness || [];
    }

    const transformedData = reviewhelpfullnessData.map((item) => ({
        name: `${item.rating} star`,
        value:
            insightTabData?.channel === 'amazon' ||
                selectedReport?.channel === 'amazon'
                ? item.helpfull_per
                : item.likes_per,
    }));


    const renderReviewScreen = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph
                    data={transformedData}
                    maxWidth={'70%'}
                    type={'%'}
                    barHeight={'32px'}
                />
            ) : (
                <BarGraph
                    data={transformedData}
                    maxWidth={'70%'}
                    type={'%'}
                    barHeight={'32px'}
                />
            ),
        'Pie Chart': <RatingDonutChart data={transformedData} type='percentage' />,
        'Donut Chart': <DonutChart data={transformedData} type='percentage' />,
        'Funnel Chart': (
            <FunnelGraph data={transformedData} maxWidth={'80%'} type={'%'} />
        ),
    };

    return (
        <Wrapper >
            <div style={{ width: '100%' }}>

                {/* {insightTabData?.channel === 'amazon' ||
                        selectedReport?.channel === 'amazon' ? (
                        <WordCloudAnalysis
                            insightsData={
                                filteredData ? filteredData?.insights_tab : planSpecificData?.insights_tab
                            }
                        />
                    ) : (
                        <WordCloudAnalysis
                            insightsData={
                                filteredData
                                    ? filteredData?.insights
                                    : planSpecificData?.insights_tab
                            }
                        />
                    )} */}
                {
                    child?.length > 0 && child[0]?.value &&
                    <div className="pdf-page" style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', width: '100%' }}>
                        <WordCloudAnalysis
                            checkboxData={child?.length > 0 && child[0]?.child}
                            selectedButton={child?.length > 0 && child[0]?.value && 'sentiments'}
                            child={child}
                            showSwitchTab={true}
                            insightsData={filteredData ? filteredData : planSpecificData?.insights_tab}
                            hideDropdown={hideDropdown}
                            startDate={formattedDate
                            }
                            endDate={endDateParsed
                            }
                        />
                    </div>
                }
                {
                    child?.length > 0 && child[1]?.value &&
                    <div className="pdf-page" style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', width: '100%', marginTop: '50px' }}>
                        <WordCloudAnalysis
                            checkboxData={child?.length > 0 && child[1]?.child}
                            selectedButton={child?.length > 0 && child[1]?.value && 'emotions'}
                            child={child}
                            showSwitchTab={true}
                            insightsData={filteredData ? filteredData : planSpecificData?.insights_tab}
                            hideDropdown={hideDropdown}
                            startDate={formattedDate
                            }
                            endDate={endDateParsed
                            }
                        />
                    </div>
                }
            </div>
            {
                child?.length > 0 && child[1]?.value &&
                <div className="pdf-page" style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', width: '100%', marginTop: '50px' }}>
                    {view === 'vertical' ? (
                        <PieWrapper>
                            <HeadingSpan>
                                <TitleWithInfo
                                    title={'Review Helpfulness'}
                                    infoText={`The average rating represents the overall score from all reviews, providing an indication of general satisfaction.`}
                                />
                            </HeadingSpan>
                            <div>
                                <VerticalBarGraph
                                    data={transformedData}
                                    type={'%'}
                                    maxWidth={'50%'}
                                />
                            </div>
                        </PieWrapper>
                    ) : (
                        child?.length > 0 && (child[4]?.value || child[5]?.value) &&
                        <EmotionAnalysisWrapper>
                            {/* { */}
                            {/* // insightTabData?.channel === 'flipkart' ||
                            //     selectedReport?.channel === 'flipkart' ? (
                            //     <HeadingSpan><TitleWithInfo */}
                            {/* //         title={'Review Liked'}
                            //         infoText={`The average rating represents the overall score from all reviews, providing an indication of general satisfaction.`}
                            //     /></HeadingSpan> */}
                            {/* // ) : (
                            //     <HeadingSpan><TitleWithInfo */}
                            {/* //         title={'Review Helpfulness'}
                            //         infoText={`The average rating represents the overall score from all reviews, providing an indication of general satisfaction.`}
                            //     /></HeadingSpan> */}
                            {/* // )} */}

                            {/* // <BarGraph data={transformedData} type={'%'} maxWidth={'50%'} /> */}

                            <GraphHOC
                                title={
                                    insightTabData?.channel === 'flipkart' ||
                                        selectedReport?.channel === 'flipkart'
                                        ? 'Review Liked'
                                        : 'Review Helpfulness'
                                }
                                options={options}
                                renderScreen={renderReviewScreen}
                                graphWidth={'100%'}
                                margin={'20px 0px 45px 50px'}
                                startDate={formattedDate
                                }
                                endDate={endDateParsed
                                }
                                option={
                                    child[4]?.child?.find(i => i?.value === true)?.name || child[5]?.child?.find(i => i?.value === true)?.name
                                }
                                infoText='This chart displays the average number of reviews liked based on different satisfaction levels'
                            />
                        </EmotionAnalysisWrapper>
                    )}
                </div>
            }

            {(plan === 'BrandAstraPro' || plan === 'AstraLite') && (
                <>
                    {filterOption ? (
                        <EmotionAnalysisWrapper className="pdf-page" style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}>
                            <p> <TitleWithInfo
                                title={'Emotion Analysis'}
                                infoText={`The average rating represents the overall score from all reviews, providing an indication of general satisfaction.`}
                            /></p>
                            <FilteredGraphContainer>
                                {emotionAnalysisGraph}
                            </FilteredGraphContainer>
                        </EmotionAnalysisWrapper>
                    ) : (
                        child?.length > 0 && child[2]?.value == true &&
                        <div className="pdf-page" style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', marginTop: '40px' }}>
                            <EmotionAnalysisWrapper>
                                <GraphHOC
                                    title={'Emotion Analysis'}
                                    options={options}
                                    renderScreen={renderScreen}
                                    graphWidth={'100%'}
                                    margin={'20px 0px 20px 50px'}
                                    startDate={formattedDate
                                    }
                                    endDate={endDateParsed
                                    }
                                    option={
                                        child[2]?.child?.find(i => i?.value === true)?.name
                                    }
                                    hideDropdown={hideDropdown}
                                />
                            </EmotionAnalysisWrapper>
                        </div>
                    )}
                    {
                        child?.length > 0 && child[3]?.value == true &&
                        <>
                            {
                                <div className="pdf-page" style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', marginTop: '30px' }} >
                                    <CorrelationGraph
                                        RatingLabels={[
                                            'Rating',
                                            'Helpful',
                                            'Anger',
                                            'Neutral',
                                            'Sadness',
                                            'Disgust',
                                            'Fear',
                                            'Joy',
                                            'Surprise',
                                        ]}
                                        correlationAnalysisData={
                                            filteredData
                                                ? filteredData?.insights?.correlation_analysis
                                                : planSpecificData?.insights_tab?.correlation_analysis
                                        }
                                        hideDropdown={hideDropdown}
                                        startDate={formattedDate
                                        }
                                        endDate={endDateParsed
                                        }
                                    />
                                </div>
                            }
                        </>
                    }
                    {
                        child?.length > 0 && child[6]?.value == true &&
                        <>
                            {insightTabData?.channel === 'flipkart' ||
                                selectedReport?.channel === 'flipkart' ? (
                                <MapContainer className="pdf-page" style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', }} >
                                    <p> <TitleWithInfo
                                        title={'Geographical View of Sentiments'}
                                        infoText={`The average rating represents the overall score from all reviews, providing an indication of general satisfaction.`}
                                    /></p>
                                    <Container1 className="pdf-page" style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', }}>
                                        {
                                            child?.length > 0 && child[6]?.child?.length > 0 && child[6]?.child[0]?.value &&

                                            <MapTableView
                                                countType={'Engagers Count'}
                                                data={
                                                    selectedReport?.data?.insights_tab?.map_data
                                                        ?.reviews_by_state ||
                                                    insightTabData?.data?.insights_tab?.map_data
                                                        ?.reviews_by_state

                                                } />
                                        }
                                        {
                                            child?.length > 0 && child[6]?.child?.length > 0 && child[6]?.child[1]?.value &&
                                            <MapTableView
                                                countType={'Positive review Count'}
                                                data={
                                                    selectedReport?.data?.insights_tab?.map_data
                                                        ?.positive_reviews_by_state ||
                                                    insightTabData?.data?.insights_tab?.map_data
                                                        ?.positive_reviews_by_state

                                                } />

                                        }

                                    </Container1>
                                    <Container1 className="pdf-page" style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid', }}>
                                        {
                                            child?.length > 0 && child[6]?.child?.length > 0 && child[6]?.child[2]?.value &&
                                            <MapTableView
                                                countType={'Neutral review Count'}
                                                data={
                                                    selectedReport?.data?.insights_tab?.map_data
                                                        ?.neutral_reviews_by_state ||
                                                    insightTabData?.data?.insights_tab?.map_data
                                                        ?.neutral_reviews_by_state

                                                } />

                                        }

                                        {
                                            child?.length > 0 && child[6]?.child?.length > 0 && child[6]?.child[3]?.value &&
                                            <MapTableView
                                                countType={'Negative review Count'}
                                                data={
                                                    selectedReport?.data?.insights_tab?.map_data
                                                        ?.negative_reviews_by_state ||
                                                    insightTabData?.data?.insights_tab?.map_data
                                                        ?.negative_reviews_by_state

                                                } />
                                        }

                                    </Container1>
                                    <Span>{` This data is observed from ${formattedDate} to ${endDateParsed}`}</Span>

                                </MapContainer>
                            )
                                :
                                null

                            }

                        </>

                    }

                </>
            )}

        </Wrapper>
    );
}

export default InsightsTab;
