import React, { useState } from 'react';
import styled from 'styled-components';
import TitleWithInfo from '../../../../Common/TitleWithInfo/TitleWithInfo';
// import SmallSelect from '../Select/SmallSelect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  pageBreakInside: avoid
`;

const Title = styled.div`
  color: #636363;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ParagraphTitle = styled.p`
margin:0px;
display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    min-height:${(props) => props?.titleText ? '50px' : ''};
`
const MainContainer = styled.div`
  flex: 1;
  display: flex;
  width: ${(props) => props.graphWidth};
  margin: ${(props) => props.margin};
  align-items: center;
  pageBreakInside: avoid
`;

const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
`;

const GraphHOC = ({
  title = '',
  renderScreen = () => {},
  options = [],
  graphWidth = '100%',
  margin = '0px',
  startDate = '',
  endDate = '',
  option,
  infoText='aaaaa'
}) => {
  const [selectedGraph, setSelectedGraph] = useState(
    option);

  const handleSelectedGraph = (option) => {
    setSelectedGraph(option);
  };

  return (
    <Wrapper>
      <Title>
      <ParagraphTitle titleText={title}>
        <TitleWithInfo
              title={title}
              infoText={infoText}
            />
        </ParagraphTitle>
        {/* <p>{title}</p> */}
        {/* <SmallSelect onChange={handleSelectedGraph} options={options} /> */}
      </Title>
      <MainContainer graphWidth={graphWidth} margin={margin}>
        {renderScreen[selectedGraph]}
      </MainContainer>
      {startDate && (
        <Span>
          This data is observed from {startDate} to {endDate}
        </Span>
      )}
    </Wrapper>
  );
};

export default GraphHOC;
