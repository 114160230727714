import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import CheckIcon from '@mui/icons-material/Check';
import { location } from '../../../utils/locationList';
import { CrossIcon } from '../../../Common/SvgIcon/CommonSvgIcons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px 15px 15px 15px;
  border: 1px solid #a2a2a2;
  padding-bottom: 20px;
`;

const StyledSelect = styled(Select)`
  & > div > :nth-child(1) {
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  width: 97%;
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  & > :last-child {
    position: absolute;
    top: 45%;
    right: 12%;
    pointer-events: none;
  }
`;

const BottomWrapper = styled.div`
  width: 60%;
  position: relative;
  padding-top: 12px;
  padding-bottom: 10px;
  & > :last-child {
    position: absolute;
    top: 35%;
    right: 14%;
    pointer-events: none;
  }
`;
const Label2 = styled.span`
  color: #636363;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
`;

const RadiusWrapper = styled.div`
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
  flex-direction: column;
  border-top: 1px solid #a2a2a2;
`;

const RangeSpan = styled.span`
  color: rgba(99, 99, 99, 0.49);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '90%',
    borderRadius: '15px',
    border: '1px solid #A2A2A2',
    marginLeft: '0px',
    background: '#FFF',
    minHeight: '54px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#636363',
    fontSize: '16px',
    textAlign: 'left',
    marginLeft: '10px',
    fontFamily: 'Montserrat',
    //fontWeight:'500',
  }),
  option: (provided, state) => ({
    ...provided,
    width: '1518px',
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#636363',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#F2F2FE',
    borderRadius: '40px',
    width: '90px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#636363',
  }),
};
export const ErrorMessage = styled.div`
  padding: 2px 10px;
  color: #d74748;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;
const LocationTwitter = ({
  onChange,
  placeholder,
  onChangeRadius,
  option = location,
  hideRadius,
  onCityChange,
}) => {
  const [showSubItem, setShowSubItem] = useState(false);
  const [textInputValue, setTextInputValue] = useState(10);
  const [selectedCity, setSelectedCity] = useState(null);

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    onCityChange(selectedOption ? selectedOption.value : '');
    setTextInputValue(10);
  };

  const handleRadiusChange = (event) => {
    const inputValue = Number(event.target.value);
    if (inputValue > selectedCity?.radius) {
      setTextInputValue(selectedCity.radius);
      onChangeRadius(selectedCity.radius);
    } else {
      setTextInputValue(String(inputValue)?.replace(/^0+/, ''));
      onChangeRadius(inputValue);
    }
  };
  const [citiesToDisplay, setCitiesToDisplay] = useState();
  const [cityOptions, setCityOptions] = useState([]);
  const [locationSelected, setLocationSelected] = useState();

  useEffect(() => {
    const cityTemp = citiesToDisplay?.map((loc) => ({
      label: loc?.name,
      value: loc?.value,
      radius: loc?.radius,
    }));
    setCityOptions(cityTemp);
  }, [citiesToDisplay, selectedCity]);

  const optionStyle = {
    marginLeft: '5px',
    color: '#707070',
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '12px',
  };

  const IconStyle = {
    width: '25px',
    height: '25px',
    border: '1px solid rgba(152, 152, 152, 0.24)',
    margin: '8px 8px 0px 8px',
    borderRadius: '50%',
    color: '#fff',
    background: '#fff',

    /*  ${({ active }) => active === "false" && "background: #fff"}
  `; */
  };
  const Option = ({ children, isSelected, innerProps }) => (
    <div
      style={{
        textAlign: 'left',
        display: 'flex',
        paddingLeft: '15px',
        paddingBottom: '10px',
      }}
      {...innerProps}
    >
      <span style={optionStyle}>{children}</span>
    </div>
  );
console.log(selectedCity,'selectedCity',textInputValue);

  return (
    <Wrapper>
      <InputWrapper>
        <StyledSelect
          options={option}
          isMulti={false}
          styles={customStyles}
          value={locationSelected !== undefined ? locationSelected : undefined}
          components={{ Option }}
          onChange={(selected) => {
            setCitiesToDisplay(selected?.cities);
            const values = selected
              ? selected?.value + ',' + selected?.radius
              : {};
            setLocationSelected(selected);
            onChange(values);
            setShowSubItem(!hideRadius);
          }}
          placeholder={placeholder}
        />
        {locationSelected?.value?.length > 0 ? (
          <button
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              marginLeft: '8px',
              pointerEvents: 'auto',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setTextInputValue(undefined);
              onChange(undefined);
              onCityChange(null);
              setSelectedCity(null);
              setLocationSelected(null);
              setCityOptions([]);
            }}
          >
            {CrossIcon}
          </button>
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </InputWrapper>
      {showSubItem && (
        <RadiusWrapper>
          <Label2>Specify the City and radius for geospatial sweeping</Label2>
          <div style={{ display: 'flex' }}>
            <BottomWrapper>
              <Select
                options={cityOptions}
                value={selectedCity}
                onChange={handleCityChange}
                components={{ Option }}
                placeholder='Select City'
                styles={customStyles}
              />
              {selectedCity?.value?.length > 0 ? (
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    marginLeft: '8px',
                    pointerEvents: 'auto',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setTextInputValue(undefined);
                    onCityChange(null);
                    setSelectedCity(null);
                  }}
                >
                  {CrossIcon}
                </button>
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </BottomWrapper>
            <input
              type='number'
              placeholder='Enter radius (in kms)'
              value={
                ((selectedCity != null || selectedCity != undefined))
                  ?  textInputValue
                  : ''
                  
              }
              disabled={selectedCity === null || selectedCity === undefined}
              onChange={handleRadiusChange}
              // min={10}
              style={{
                width: '30%',
                padding: '10px',
                borderRadius: '15px',
                height: '35px',
                alignSelf: 'center',
                border: '1px solid #A2A2A2',
              }}
            />
          </div>
          {selectedCity?.radius !== null &&
          selectedCity?.radius !== undefined &&
          textInputValue > 10 &&
          selectedCity?.radius > 0 ? (
            <RangeSpan>Range up to {selectedCity?.radius} km</RangeSpan>
          ) : (
            <></>
          )}
          {textInputValue < 10 && (
            <ErrorMessage>Enter radius above 10km</ErrorMessage>
          )}
        </RadiusWrapper>
      )}
    </Wrapper>
  );
};

export default LocationTwitter;
