import React, { useState } from 'react';
import Lottie from 'lottie-react';
import Loader from '../../../../assests/animation/Loader2.json'
import OverallReview from '../../../../components/OverallReview/OverallReview';
import { FacebookLogo, X_icon } from '../../../../Common/SvgIcon/CommonSvgIcons';
import GraphHOC from '../ReportDownload/GraphHOC';
// import WordCloudComponent from '../../../components/WordCloud/WordCloud';
// import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUserActivity';
// import TrendsInUserActivity from '../../../../Common/TendsInUserActivity/TrendsInUser_activity';

import BarGraph from '../../../../Common/BarGraph/BarGraph';
import RatingDonutChart from '../../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../../Common/FunnelGraph/FunnelGraph';
import VerticalBarGraph from '../../../../Common/BarGraph/VarticalBargraph';
import { Bar } from 'recharts';
import {
  Wrapper,
  Container,
  ChildContainer,
  FilterContainer
} from './Overview.styled'
import TrendsInUserActivity from './TrendInUserActivity';
import VerifiedAndNonVerifiedUsers from '../../../../Common/VerifiedNonVerifiedUserGraph/VerifiedNonVerifiedUserGraph';
import { useParsedEndDate, useParsedStartDate } from '../../../../utils/ParseDate';


const Overview = ({
  channel,
  overview_tab,
  startDate = '',
  endDate = '',
  filteredStartDate,
  filteredEndDate,
  filterOption,
  filteredData,
  resetFlag,
  view,
  child,
  hideDropdown,

  formatValue
}) => {
  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

  const genderDemographicsData = [
    {
      name: 'Male',
      value: overview_tab?.gender_demographics?.male_percentage,
      // fill: '#6640B8',
      fill: 'rgba(13, 135, 246, 1)',
    },
    {
      name: 'Female',
      value: overview_tab?.gender_demographics?.female_percentage,
      // fill: '#EAAABD',
      fill: 'rgba(246, 79, 129, 1)',
    },
    {
      name: 'Unknown',
      value: overview_tab?.gender_demographics?.unknown_percentage,
      // fill: '#E4E4E4',
      fill: 'rgba(218, 217, 219, 1)',
    },
  ];

  const TopActiveUsers = overview_tab?.top_active_users || []

  const engagementGraphData = [
    {
      name: 'Likes',
      value: overview_tab?.engagement?.likes || 0,
      // fill: '#6640B8',
      fill: 'rgba(53, 60, 247, 0.5)',
    },
    {
      name: 'Comments',
      value: overview_tab?.engagement?.comments || 0,
      // fill: '#EAAABD',
      fill: 'rgba(0, 213, 208, 1)',
    },
    // {
    //   name: 'Shares',
    //   value: overview_tab?.engagement?.shares || 0,
    //   fill: '#E4E4E4',
    // },
  ];
  const engagementGraphDataFOrTwitter = [
    {
      name: 'Likes',
      value: overview_tab?.engagement?.likes || 0,
      fill: '#353CF780',
    },
    {
      name: 'Replies',
      value: overview_tab?.engagement?.comments || 0,
      fill: '#00D5D0',
    },
    {
      name: 'Views',
      value: overview_tab?.engagement?.views || 0,
      fill: '#CE66FF',
    },
    {
      name: 'Reposts',
      value: overview_tab?.engagement?.reposts || 0,
      fill: '#A2FF82',
    },
  ];

  const totalNoOfPosts = overview_tab?.total_count

  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={genderDemographicsData}
          type={'%'}
          view={view}
        />
      ) : (
        <BarGraph
          data={genderDemographicsData}
          type={'%'}
          // borderRadius={'0px 12px 12px 0px'}
          barHeight={'32px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={genderDemographicsData}
        width={450}
        type='percentage'
      />
    ),
    'Donut Chart': (
      <DonutChart data={genderDemographicsData} type='percentage' />
    ),
    'Funnel Chart': <FunnelGraph data={genderDemographicsData} type={'%'} />,
  };

  // const renderEngagement = {
  //   'Bar Graph':
  //     view === 'vertical' ? (
  //       <VerticalBarGraph
  //         data={engagementGraphData}
  //         // type={'%'}
  //         view={view}
  //       />
  //     ) : (
  //       <BarGraph
  //         data={engagementGraphData}
  //         // type={'%'}
  //         borderRadius={'0px 0px 0px 0px'}
  //         barHeight={'35px'}
  //         view={view}
  //       />
  //     ),
  //   'Pie Chart': (
  //     <RatingDonutChart
  //       data={engagementGraphData}
  //       width={450}
  //     // type='percentage'
  //     />
  //   ),
  //   'Donut Chart': (
  //     <DonutChart data={engagementGraphData} />
  //   ),
  //   'Funnel Chart': <FunnelGraph data={engagementGraphData} />,
  // };

  const renderEngagement = (data) => ({
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={data}
          type={data[0]?.name === 'Likes' ? null : '%'}
          view={view}
        />
      ) : (
        <BarGraph
          data={data}
          type={data[0]?.name === 'Likes' ? null : '%'}
          /*  borderRadius={'0px 12px 12px 0px'} */
          barHeight={'32px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={data}
        width={450}
        type={data[0]?.name === 'Likes' ? null : 'percentage'}
      />
    ),
    'Donut Chart': (
      <DonutChart
        data={data}
        type={data[0]?.name === 'Likes' ? null : 'percentage'}
      />
    ),
    'Funnel Chart': (
      <FunnelGraph data={data} type={data[0]?.name === 'Likes' ? '' : '%'} />
    ),
  });
  let genderDemographicsGraph;

  if (filterOption) {
    if (filterOption === 'Bar Graph') {
      genderDemographicsGraph = renderScreen['Bar Graph'];
    } else if (filterOption === 'Pie Chart') {
      genderDemographicsGraph = renderScreen['Pie Chart'];
    } else if (filterOption === 'Donut Chart') {
      genderDemographicsGraph = renderScreen['Donut Chart'];
    } else {
      genderDemographicsGraph = renderScreen['Funnel Chart'];
    }
  }


  const filteredValue = child?.length > 0 && child[0]?.child.reduce((acc, val) => {
    if (val.value === true) {
      acc = val.name;
    }
    return acc;
  }, undefined);

  const filteredGraphValue = child?.length > 0 && child[1]?.child.reduce((acc, val) => {
    if (val.value === true) {
      acc = val.name;
    }
    return acc;
  }, undefined);

  const formattedDate = useParsedStartDate(false, '', startDate, formatValue);
  const endDateParsed = useParsedEndDate(false, '', endDate, formatValue);
  console.log(child, "child in overviewa");
  const userVerificationData = [
    {
      name: 'Verified',
      value: overview_tab?.verified_nonverified?.verified || 0,
      fill: '#353CF780',
    },
    {
      name: 'Non Verified',
      value: overview_tab?.verified_nonverified?.nonverified || 0,
      fill: '#00D5D0',
    },
  ];
  return (
    <>
      {
        resetFlag === true && filteredData === undefined ? (
          <div style={{ marginTop: '-80px' }}>
            <Lottie
              data-testid='loader'
              animationData={Loader}
              loop={true}
              width='100px'
              height='100px'
            />
          </div>
        ) : (
          <Wrapper>
            <Container>
              <ChildContainer>
                <OverallReview
                  backgroundColor='rgba(0, 213, 208, 0.5)'
                  icon={channel == 'facebook' ? FacebookLogo : X_icon}
                  message={`${totalNoOfPosts} Relevant posts were observed From ${formattedDate} to ${endDateParsed}`}
                />
              </ChildContainer>
              {/* {filterOption ? (
                <FilterContainer>
                  <span>Gender Demographics</span>
                  <div>{genderDemographicsGraph}</div>
                </FilterContainer>
              ) : ( */}
              <ChildContainer className="pdf-page"
                style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
              >
                {channel == 'facebook' ?
                  child?.length > 0 && child[0]?.value == true &&
                  <GraphHOC
                    title={'Engagement'}
                    options={options}
                    renderScreen={renderEngagement(engagementGraphData)}

                    startDate={formattedDate}
                    endDate={endDateParsed}
                    option={
                      child[0]?.child?.find(i => i?.value === true)?.name
                    }
                    hideDropdown={hideDropdown}
                  />
                  :
                  channel == 'twitter' ?
                    child?.length > 0 && child[0]?.value == true &&
                    <GraphHOC
                      title={'Engagement'}
                      options={options}
                      renderScreen={renderEngagement(engagementGraphDataFOrTwitter)}

                      startDate={formattedDate}
                      endDate={endDateParsed}
                      option={
                        child[0]?.child?.find(i => i?.value === true)?.name
                      }
                      hideDropdown={hideDropdown}
                    />
                    :
                    child?.length > 0 && child[0]?.value == true &&

                    <GraphHOC
                      title={'Gender Demographics'}
                      options={options}
                      renderScreen={renderScreen}
                      startDate={formattedDate}
                      endDate={endDateParsed}
                      option={
                        child[0]?.child?.find(i => i?.value === true)?.name
                      }
                      hideDropdown={hideDropdown}
                    />
                }
              </ChildContainer>
              {/* )} */}
            </Container>


            {channel == 'facebook' ?
              child?.length > 0 && child[1]?.value == true &&
              <ChildContainer className="pdf-page"
                style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
              >
                <GraphHOC
                  title={'Gender Demographics'}
                  options={options}
                  renderScreen={renderScreen}
                  startDate={formattedDate}
                  endDate={endDateParsed}
                  option={
                    child[1]?.child?.find(i => i?.value === true)?.name
                  }
                  hideDropdown={hideDropdown}
                />
              </ChildContainer>
              :
              child?.length > 0 && (child[1]?.value == true || child[2]?.value == true) &&
              <Container>
                {child?.length > 0 && child[1]?.value == true &&
                  <ChildContainer className="pdf-page"
                    style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
                  >
                    <GraphHOC
                      title={'Gender Demographics'}
                      options={options}
                      renderScreen={renderScreen}
                      startDate={formattedDate}
                      endDate={endDateParsed}
                      option={
                        child[1]?.child?.find(i => i?.value === true)?.name
                      }
                      hideDropdown={hideDropdown}
                    />
                  </ChildContainer>
                }

                {
                  child?.length > 0 && child[2]?.value == true &&
                  <ChildContainer className="pdf-page"
                    style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
                  >
                    <GraphHOC
                      title={'Verified and Non verified Users'}
                      options={options}
                      renderScreen={renderEngagement(userVerificationData)}
                      startDate={formattedDate}
                      endDate={endDateParsed}
                      option={
                        child[2]?.child?.find(i => i?.value === true)?.name
                      }
                      hideDropdown={hideDropdown}
                    />
                  </ChildContainer>
                }
              </Container>
            }
            {
              channel == 'twitter' ?

              child?.length > 0 && child[3]?.value == true &&
              <ChildContainer
                className="pdf-page"
                style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
              >
                <TrendsInUserActivity
                  // showLineGraph={'Line Graph'}
                  data1={{
                    trends_in_user_activity: overview_tab.volume,
                  }}
                  title={`Volume`}
                  ylabel={'Volume'}
                  // countName = {'engagement_count'}
                  // option='Weeks'
                  hideDropdown={hideDropdown}
                  startDate={formattedDate}
                  endDate={endDateParsed}
                  infoText={'Track the total number of posts over a specified time period.'}
                  showLineGraph={child[3]?.child?.find(i => i?.value === true)?.name}
                  option={child[3]?.child[0]?.child?.find(i => i?.value === true)?.name || child[3]?.child[1]?.child?.find(i => i?.value === true)?.name}

                />
              </ChildContainer>
              :

              child?.length > 0 && child[2]?.value == true &&
              <ChildContainer
                className="pdf-page"
                style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
              >
                <TrendsInUserActivity
                  // showLineGraph={'Line Graph'}
                  data1={{
                    trends_in_user_activity: overview_tab.volume,
                  }}
                  title={`Volume`}
                  ylabel={'Volume'}
                  // countName = {'engagement_count'}
                  // option='Weeks'
                  hideDropdown={hideDropdown}
                  startDate={formattedDate}
                  endDate={endDateParsed}
                  infoText={'Track the total number of posts over a specified time period.'}
                  showLineGraph={child[2]?.child?.find(i => i?.value === true)?.name}
                  option={child[2]?.child[0]?.child?.find(i => i?.value === true)?.name || child[2]?.child[1]?.child?.find(i => i?.value === true)?.name}

                />
              </ChildContainer>
            }
            {
              channel == 'twitter' ?

                child?.length > 0 && child[4]?.value == true &&
                // (child[4]?.child[0]?.value == true || child[4]?.child[1]?.value == true )&&
                <ChildContainer
                  className="pdf-page"
                  style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
                >
                  <TrendsInUserActivity
                    // option={child[1]?.child[0]?.child?.find(i => i?.value === true)?.name}
                    // showLineGraph={'Line Graph'}
                    data1={{
                      trends_in_user_activity: overview_tab.trends_in_user_activity,
                    }}
                    ylabel={'Engagement'}
                    title='Trends in User Activity'
                    hideDropdown={hideDropdown}
                    startDate={formattedDate}
                    endDate={endDateParsed}
                    // option='Years'
                    showLineGraph={child[4]?.child?.find(i => i?.value === true)?.name}
                    option={child[4]?.child[0]?.child?.find(i => i?.value === true)?.name || child[4]?.child[1]?.child?.find(i => i?.value === true)?.name}
                    // hideDropdown={hideDropdown}
                    typeVal='instagram'
                  />
                </ChildContainer>
                :
                child?.length > 0 && child[3]?.value == true &&
                child[3]?.child[0]?.value == true &&
                <ChildContainer
                  className="pdf-page"
                  style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
                >
                  <TrendsInUserActivity
                    // option={child[1]?.child[0]?.child?.find(i => i?.value === true)?.name}
                    // showLineGraph={'Line Graph'}
                    data1={{
                      trends_in_user_activity: overview_tab.trends_in_user_activity,
                    }}
                    ylabel={'Engagement'}
                    title='Trends in User Activity'
                    hideDropdown={hideDropdown}
                    startDate={formattedDate}
                    endDate={endDateParsed}
                    // option='Years'
                    showLineGraph={child[3]?.child?.find(i => i?.value === true)?.name}
                    option={child[3]?.child[0]?.child?.find(i => i?.value === true)?.name || child[3]?.child[1]?.child?.find(i => i?.value === true)?.name}
                    // hideDropdown={hideDropdown}
                    typeVal='instagram'
                  />
                </ChildContainer>
            }


            {/* {child?.length > 0 && child[1]?.value == true &&
              child[1]?.child[1]?.value == true &&

              <ChildContainer className="pdf-page"
                style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
              >
                <TrendsInUserActivity
                  showLineGraph={'Area Graph'}
                  data1={{
                    trends_in_user_activity: overview_tab.trends_in_user_activity,
                  }}
                  option={child[1]?.child[1]?.child?.find(i => i?.value === true)?.name}
                  ylabel='Reviews'
                  title='Trends In User Activity'
                  hideDropdown={hideDropdown}

                  startDate={formattedDate}
                  endDate={endDateParsed}
                />

              </ChildContainer>
            } */}

            {child?.length > 0 && child[2]?.value == true &&
              channel == 'facebook' || channel == 'twitter' ?
              <></>
              :
              <ChildContainer className="pdf-page"
                style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
              >
                <GraphHOC
                  title={'Engagement'}
                  options={options}
                  renderScreen={renderEngagement}

                  startDate={formattedDate}
                  endDate={endDateParsed}
                  option={
                    child[2]?.child?.find(i => i?.value === true)?.name
                  }
                  hideDropdown={hideDropdown}
                />

              </ChildContainer>
            }

            {child?.length > 0 && child[5]?.value == true &&
              channel == 'twitter' &&

              <ChildContainer className="pdf-page"
                style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
              >
                <TrendsInUserActivity

                  data1={{
                    trends_in_user_activity: overview_tab.impression_trends,
                  }}
                  ylabel='Impressions'
                  title='Impressions'
                  hideDropdown={hideDropdown}
                  startDate={formattedDate}
                  endDate={endDateParsed}
                  infoText={
                    'Tracks total impressions, representing an estimate of how many times posts could have been viewed, calculated as the sum of the follower counts of users whose posts are included in the topic during the specified time period.'
                  }

                  // option='Years'
                  showLineGraph={child[5]?.child?.find(i => i?.value === true)?.name}
                  option={child[5]?.child[0]?.child?.find(i => i?.value === true)?.name || child[5]?.child[1]?.child?.find(i => i?.value === true)?.name}
                  // hideDropdown={hideDropdown}
                  typeVal='instagram'
                />

              </ChildContainer>
            }

            {/* {child?.length > 0 && child[3]?.value == true &&
              <ChildContainer className="pdf-page"
                style={{ pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid' }}
              >
                <VerifiedAndNonVerifiedUsers
                  title={'Top Active Users'}
                  dontShowStar={true}
                  data={TopActiveUsers}
                  Width={100}

                  graphStyles={{
                    display: 'grid',
                    gridTemplateColumns: '200px 1fr',
                    gap: '15px',
                    alignItems: 'center'
                  }}
                  Contents='contents'
                  startDate={formattedDate}
                  endDate={endDateParsed}
                />
              </ChildContainer>


            } */}
          </Wrapper>
        )
      }
    </>
  );

};

export default Overview;
