import React, { useState, useEffect } from 'react';
import CustomWordCloud from '../../../../Common/InsightsWordCloud/CustomWordCloud';
import GraphDropdown from '../../../../Common/GraphDropDown/GraphDropdown';
import {
  Sentiment,
  Container, Button, CheckBoxContainer, Title
} from '../ReportDownload/WordCloudAnalysis.styled'
import styled from 'styled-components';
import TitleWithInfo from '../../../../Common/TitleWithInfo/TitleWithInfo';

const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;
const WordCloudAnalysis = ({ checkboxData, insightsData, selectedButton, hideDropdown, startDate, endDate}) => {
  // const [selectedButton, setSeletedButton] = useState('sentiments');
  const [selectedEmotions, setSelectedEmotions] = useState([]);
  const [selectedSentiments, setSelectedSentiments] = useState([]);
  const [wordCloudType, setWordCloudType] = useState('Unigram');



  const getWordsData = () => {
    // Replace the following example data with your actual data creation logic
    const isPositiveSelected = selectedSentiments.includes('Positive');
    const isNegativeSelected = selectedSentiments.includes('Negative');
    const isNeutralSelected = selectedSentiments.includes('Neutral');

    const isJoySelected = selectedEmotions.includes('Joy');
    const isSurPriseSelected = selectedEmotions.includes('Surprise');
    const isNeutralEmotion = selectedEmotions.includes('Neutral');
    const isAngerSelected = selectedEmotions.includes('Anger');
    const isDisgustSelected = selectedEmotions.includes('Disgust');
    const isSadnessSelected = selectedEmotions.includes('Sadness');
    const isFearSelected = selectedEmotions.includes('Fear');

    const data = insightsData?.sentiment_wordcloud || [];

    const emotionData = insightsData?.emotion_wordcloud || [];

    const wordsArray = [];
    const selectedWords = [];

    const wordCloudData = {
      positiveUnigram: data?.positive_unigram || [],
      negativeUnigram: data?.negative_unigram || [],
      neutralUnigram: data?.neutral_unigram || [],
      positiveBigram: data?.positive_bigram || [],
      negativeBigram: data?.negative_bigram || [],
      neutralBigram: data?.neutral_bigram || [],
      positiveTrigram: data?.positive_trigram || [],
      negativeTrigram: data?.negative_trigram || [],
      neutralTrigram: data?.neutral_trigram || [],
      joyUnigram: emotionData?.joy_unigram || [],
      joyBigram: emotionData?.joy_bigram || [],
      joyTrigram: emotionData?.joy_trigram || [],
      surpriseUnigram: emotionData?.surprise_unigram || [],
      surPriseBigram: emotionData?.surprise_bigram || [],
      surpriseTrigram: emotionData?.surprise_trigram || [],
      neutralEmotionUnigram: emotionData?.neutral_unigram || [],
      neutralEmotionBigram: emotionData?.neutral_bigram || [],
      neutralEmotionTrigram: emotionData?.neutral_trigram || [],
      angerUnigram: emotionData?.anger_unigram || [],
      angerBigram: emotionData?.anger_bigram || [],
      angerTrigram: emotionData?.anger_trigram || [],
      disgustUnigram: emotionData?.disgust_unigram || [],
      disgustTrigram: emotionData?.disgust_trigram || [],
      disgustBigram: emotionData?.disgust_bigram || [],
      sadnessUnigram: emotionData?.sadness_unigram || [],
      sadnessBigram: emotionData?.sadness_bigram || [],
      sadnessTrigram: emotionData?.sadness_trigram || [],
      fearUnigram: emotionData?.fear_unigram || [],
      fearBigram: emotionData?.fear_bigram || [],
      fearTrigram: emotionData?.fear_trigram || [],
    };

    if (wordCloudType === 'Unigram') {
      if (isPositiveSelected)
        selectedWords.push(
          ...wordCloudData.positiveUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'positive',
          }))
        );
      if (isNegativeSelected)
        selectedWords.push(
          ...wordCloudData.negativeUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'negative',
          }))
        );
      if (isNeutralSelected)
        selectedWords.push(
          ...wordCloudData.neutralUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'neutral',
          }))
        );
      if (isJoySelected)
        selectedWords.push(
          ...wordCloudData.joyUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'joy',
          }))
        );
      if (isSurPriseSelected)
        selectedWords.push(
          ...wordCloudData.surpriseUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'surprise',
          }))
        );
      if (isNeutralEmotion)
        selectedWords.push(
          ...wordCloudData.neutralEmotionUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'neutralEmotion',
          }))
        );
      if (isDisgustSelected)
        selectedWords.push(
          ...wordCloudData.disgustUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'disgusted',
          }))
        );
      if (isSadnessSelected)
        selectedWords.push(
          ...wordCloudData.sadnessUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'sadness',
          }))
        );
      if (isFearSelected)
        selectedWords.push(
          ...wordCloudData.fearUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'fear',
          }))
        );
      if (isAngerSelected)
        selectedWords.push(
          ...wordCloudData.angerUnigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'anger',
          }))
        );
      wordsArray.push(...selectedWords);
    } else if (wordCloudType === 'Bigram') {
      if (isPositiveSelected)
        selectedWords.push(
          ...wordCloudData.positiveBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'positive',
          }))
        );
      if (isNegativeSelected)
        selectedWords.push(
          ...wordCloudData.negativeBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'negative',
          }))
        );
      if (isNeutralSelected)
        selectedWords.push(
          ...wordCloudData.neutralBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'neutral',
          }))
        );
      if (isJoySelected)
        selectedWords.push(
          ...wordCloudData.joyBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'joy',
          }))
        );
      if (isSurPriseSelected)
        selectedWords.push(
          ...wordCloudData.surPriseBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'surprise',
          }))
        );
      if (isNeutralEmotion)
        selectedWords.push(
          ...wordCloudData.neutralEmotionBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'neutralEmotion',
          }))
        );
      if (isDisgustSelected)
        selectedWords.push(
          ...wordCloudData.disgustBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'disgusted',
          }))
        );
      if (isSadnessSelected)
        selectedWords.push(
          ...wordCloudData.sadnessBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'sadness',
          }))
        );
      if (isFearSelected)
        selectedWords.push(
          ...wordCloudData.fearBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'fear',
          }))
        );
      if (isAngerSelected)
        selectedWords.push(
          ...wordCloudData.angerBigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'anger',
          }))
        );

      wordsArray.push(...selectedWords);
    } else if (wordCloudType === 'Trigram') {
      if (isPositiveSelected)
        selectedWords.push(
          ...wordCloudData.positiveTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'positive',
          }))
        );
      if (isNegativeSelected)
        selectedWords.push(
          ...wordCloudData.negativeTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'negative',
          }))
        );
      if (isNeutralSelected)
        selectedWords.push(
          ...wordCloudData.neutralTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'neutral',
          }))
        );
      if (isJoySelected)
        selectedWords.push(
          ...wordCloudData.joyTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'joy',
          }))
        );
      if (isSurPriseSelected)
        selectedWords.push(
          ...wordCloudData.surpriseTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'surprise',
          }))
        );
      if (isNeutralEmotion)
        selectedWords.push(
          ...wordCloudData.neutralEmotionTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'neutralEmotion',
          }))
        );
      if (isDisgustSelected)
        selectedWords.push(
          ...wordCloudData.disgustTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'disgusted',
          }))
        );
      if (isSadnessSelected)
        selectedWords.push(
          ...wordCloudData.sadnessTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'sadness',
          }))
        );
      if (isFearSelected)
        selectedWords.push(
          ...wordCloudData.fearTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'fear',
          }))
        );
      if (isAngerSelected)
        selectedWords.push(
          ...wordCloudData.angerTrigram
          .filter(({ word, count }) => word.trim() !== '' && count > 0)
          .map(({ word, count }) => ({
            text: word,
            value: count,
            color: 'anger',
          }))
        );
      wordsArray.push(...selectedWords);
    }
    return wordsArray;
  };

  const handleGraphSelect = (option) => {
    setWordCloudType(option);
  };

  useEffect(() => {
    handleGraphSelect('Unigram');
    let sentimentdata = checkboxData?.length > 0 && checkboxData.reduce((acc, val) => {
      if (val.value === true) {
        acc.push(val.name);
      }
      return acc;
    }, []);
    if (selectedButton == 'sentiments') {


      setSelectedSentiments(sentimentdata)
    }
    else {

      setSelectedEmotions(sentimentdata)
    }
  }, []);   


  return (
    <Sentiment>
      <Title><TitleWithInfo
          title={'Word cloud Analysis'}

          infoText={
            `A word cloud based on user sentiments or emotions, generated from keywords, hashtags, mentions, or emojis. The cloud can be represented using unigram, bigram, or trigram phrases to highlight key topics and trends.`}
        />
        {/* Word cloud Analysis */}
        </Title>
      <Container>
        <div>
          <Button
            selected={selectedButton === 'sentiments'}
          // onClick={() => handleButtonClick('sentiments')}
          >
            Sentiments
          </Button>
          <Button
            selected={selectedButton === 'emotions'}
          // onClick={() => handleButtonClick('emotions')}
          >
            Emotions
          </Button>
        </div>
        {
          !hideDropdown && 
            <GraphDropdown
              options={['Unigram', 'Bigram', 'Trigram']}
              onSelectGraph={handleGraphSelect}
              /* defaultOption='Unigram' */
              placement='right'
          />
        }
      </Container>
      {selectedButton && (
        <CheckBoxContainer>
          {checkboxData?.map((checkbox, index) => (
            <div key={index}>
              <input
                type='checkbox'
                id={checkbox.id}
                checked={
                  checkbox?.value
                  // selectedSentiments.includes(checkbox.name) ||
                  // selectedEmotions.includes(checkbox.name)
                }
              // onChange={(event) =>
              //   handleCheckboxChange(event, selectedButton)
              // }
              />
              <label htmlFor={checkbox.id}>{checkbox.name}</label>
              {/* Add more checkboxes as needed */}
            </div>
          ))}
        </CheckBoxContainer>
      )}

      <CustomWordCloud
        words={getWordsData()}
        selectedSentiments={selectedSentiments}
        selectedEmotions={selectedEmotions}
        sentimentData={insightsData?.sentiment_wordcloud}
      />
      <Span>
          This data is observed from {startDate} to {endDate}
      </Span>
    </Sentiment>
  );
};

export default WordCloudAnalysis;
