import React, { useState, useEffect, useRef } from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
} from 'recharts';
import styled from 'styled-components';
const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;
min-height:300px;
  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AreaGraph = ({
  data,
  height = 260,
  ylabel,
  showTitle = '',
  typeVal = '',
}) => {
  const parentRef = useRef(null);
  const [numericWidth, setNumericWidth] = useState(400);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const parentWidth = parentRef?.current?.offsetWidth;
      setNumericWidth(parentWidth + 20);
    });

    resizeObserver.observe(parentRef?.current);

    return () => resizeObserver.disconnect();
  }, []);

  const CustomTooltip = ({ active, payload, label, ylabel, typeVal = '' }) => {
    console.log(payload, 'payload');

    if (active && payload && payload.length) {
      const {
        date,
        engagement_count,
        likes_count,
        comments_count,
        reposts,
        views,
        [ylabel?.toLowerCase() || 'reviews']: value,
      } = payload[0].payload;
      console.log(date, 'date');

      const formattedDate = (dateString) => {
        // Split the string into day, month, year
        let parts = dateString.split('/');
        if (parts.length === 3) {
          const [day, month, year] = parts;

          // Create a new Date object with the provided date values
          const dateVal = new Date(year, month - 1, day); // months are 0-indexed in JavaScript
          if (isNaN(dateVal?.getTime())) {
            return 'Invalid Date'; // Return a default error message if invalid
          }
          // Format the date as 'Month Day, Year' (e.g., March 15, 2023)
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          return new Intl.DateTimeFormat('en-US', options).format(dateVal);
        }

        if (parts.length === 2) {
          const [month, year] = parts;
          if (month && year) {
            // Create a date using the first day of the given month and year
            const dateVal = new Date(year, month - 1, 1); // months are 0-indexed in JavaScript
            if (isNaN(dateVal?.getTime())) {
              return 'Invalid Date';
            }

            // Format it as 'Month Year' (e.g., January 2024)
            const options = { year: 'numeric', month: 'long' };
            return new Intl.DateTimeFormat('en-US', options).format(dateVal);
          }
        }

        // Return the year only if the string is just a year
        if (parts.length === 1 && parts[0].length === 4) {
          return parts[0]; // Return the year as is (e.g., 2024)
        }

        // If no valid format, return an invalid date message
        return 'Invalid Date';
      };

      return (
        <div
          className='custom-tooltip'
          style={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: '10px',
          }}
        >
          <p
            style={{
              margin: '0px',
              fontSize: '12px',
              fontWeight: '500',
              color: 'rgba(34, 34, 34, 1)',
            }}
          >
            {formattedDate(date) || 'March 15, 2023'}
          </p>{' '}
          {/* Display the date */}
          <p
            style={{
              margin: '0px',
              fontSize: '12px',
              marginTop: '5px',
              fontWeight: '400',
              color: 'rgba(99, 99, 99, 1)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                background: 'rgba(53, 60, 247, 1)',
                color: 'rgba(53, 60, 247, 1)',
                height: '11px',
                width: '11px',
                borderRadius: '2px',
                display: 'inline-block',
                marginRight: '8px', // This ensures space between the circle and the text
              }}
            />{' '}
            Engagement
          </p>
          {typeVal !== 'twitter' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  margin: '0px',
                  marginTop: '7px',
                  marginBottom: '5px',
                  fontSize: '12px',
                  fontWeight: '400',
                  color: 'rgba(99, 99, 99, 1)',
                }}
              >
                <strong>Likes:</strong>{' '}
              </p>
              <p
                style={{
                  margin: '0px',
                  marginTop: '7px',
                  marginBottom: '5px',
                  fontSize: '12px',
                  fontWeight: '400',
                  color: 'rgba(99, 99, 99, 1)',
                }}
              >
                {likes_count || 0}
              </p>
            </div>
          ) : (
            likes_count !== undefined &&
            likes_count !== null && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p
                  style={{
                    margin: '0px',
                    marginTop: '7px',
                    marginBottom: '5px',
                    fontSize: '12px',
                    fontWeight: '400',
                    color: 'rgba(99, 99, 99, 1)',
                  }}
                >
                  <strong>Likes:</strong>{' '}
                </p>
                <p
                  style={{
                    margin: '0px',
                    marginTop: '7px',
                    marginBottom: '5px',
                    fontSize: '12px',
                    fontWeight: '400',
                    color: 'rgba(99, 99, 99, 1)',
                  }}
                >
                  {likes_count || 0}
                </p>
              </div>
            )
          )}
          <div
            style={{
              height: '0.5px',
              width: '100%',
              background: 'rgba(162, 162, 162, 1)',
            }}
          ></div>
          {typeVal !== 'twitter' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '5px',
              }}
            >
              <p
                style={{
                  margin: '0px',
                  fontSize: '12px',
                  fontWeight: '400',
                  color: 'rgba(99, 99, 99, 1)',
                }}
              >
                <strong>Comments:</strong>
              </p>
              <p
                style={{
                  margin: '0px',
                  fontSize: '12px',
                  fontWeight: '400',
                  color: 'rgba(99, 99, 99, 1)',
                }}
              >
                {comments_count || 0}
              </p>
            </div>
          ) : (
            comments_count !== undefined &&
            comments_count !== null && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '5px',
                }}
              >
                <p
                  style={{
                    margin: '0px',
                    fontSize: '12px',
                    fontWeight: '400',
                    color: 'rgba(99, 99, 99, 1)',
                  }}
                >
                  <strong>Comments:</strong>
                </p>{' '}
                <p
                  style={{
                    margin: '0px',
                    fontSize: '12px',
                    fontWeight: '400',
                    color: 'rgba(99, 99, 99, 1)',
                  }}
                >
                  {comments_count || 0}
                </p>
              </div>
            )
          )}
          {typeVal === 'twitter' &&
            comments_count !== undefined &&
            comments_count !== null && (
              <div
                style={{
                  height: '0.5px',
                  marginTop: '5px',

                  width: '100%',
                  background: 'rgba(162, 162, 162, 1)',
                }}
              ></div>
            )}
          {reposts !== null &&
            typeVal === 'twitter' &&
            reposts !== undefined && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '5px',
                  }}
                >
                  <p
                    style={{
                      margin: '0px',
                      fontSize: '12px',
                      fontWeight: '400',
                      color: 'rgba(99, 99, 99, 1)',
                    }}
                  >
                    <strong>Reposts:</strong>
                  </p>{' '}
                  <p
                    style={{
                      margin: '0px',
                      fontSize: '12px',
                      fontWeight: '400',
                      color: 'rgba(99, 99, 99, 1)',
                    }}
                  >
                    {reposts || 0}
                  </p>
                </div>
                <div
                  style={{
                    height: '0.5px',
                    width: '100%',
                    marginTop: '5px',

                    // background: 'rgba(162, 162, 162, 1)',
                  }}
                ></div>
              </>
            )}
          {views !== null && typeVal === 'twitter' && views !== undefined && (
            <>
              {' '}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '5px',
                }}
              >
                <p
                  style={{
                    margin: '0px',
                    fontSize: '12px',
                    fontWeight: '400',
                    color: 'rgba(99, 99, 99, 1)',
                  }}
                >
                  <strong>Views:</strong>
                </p>{' '}
                <p
                  style={{
                    margin: '0px',
                    fontSize: '12px',
                    fontWeight: '400',
                    color: 'rgba(99, 99, 99, 1)',
                  }}
                >
                  {views || 0}
                </p>
              </div>
            </>
          )}
          {/* {likes && <p><strong>Likes:</strong> {likes}</p>} Display likes */}
          {/* {comments && <p><strong>Comments:</strong> {comments}</p>} Display comments */}
        </div>
      );
    }

    return null;
  };
  const CustomTooltip2 = ({ active, payload, label, ylabel }) => {
    if (active && payload && payload.length) {
      const formattedDate = (dateString) => {
        // Split the string into day, month, year
        // const [day, month, year] = dateString.split('/');

        // // Create a new Date object with the provided date values
        // const date = new Date(year, month - 1, day); // months are 0-indexed in JavaScript
        // if (isNaN(date?.getTime())) {
        //   return 'Invalid Date'; // Return a default error message if invalid
        // }
        // // Format the date as 'Month Day, Year' (e.g., March 15, 2023)
        // const options = { year: 'numeric', month: 'long', day: 'numeric' };
        // return new Intl.DateTimeFormat('en-US', options).format(date);


        let parts = dateString.split('/');
        if (parts.length === 3) {
          const [day, month, year] = parts;

          // Create a new Date object with the provided date values
          const dateVal = new Date(year, month - 1, day); // months are 0-indexed in JavaScript
          if (isNaN(dateVal?.getTime())) {
            return 'Invalid Date'; // Return a default error message if invalid
          }
          // Format the date as 'Month Day, Year' (e.g., March 15, 2023)
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          return new Intl.DateTimeFormat('en-US', options).format(dateVal);
        }

        if (parts.length === 2) {
          const [month, year] = parts;
          if (month && year) {
            // Create a date using the first day of the given month and year
            const dateVal = new Date(year, month - 1, 1); // months are 0-indexed in JavaScript
            if (isNaN(dateVal?.getTime())) {
              return 'Invalid Date';
            }

            // Format it as 'Month Year' (e.g., January 2024)
            const options = { year: 'numeric', month: 'long' };
            return new Intl.DateTimeFormat('en-US', options).format(dateVal);
          }
        }

        // Return the year only if the string is just a year
        if (parts.length === 1 && parts[0].length === 4) {
          return parts[0]; // Return the year as is (e.g., 2024)
        }

        // If no valid format, return an invalid date message
        return 'Invalid Date';

      };
      const { [ylabel?.toLowerCase() || 'reviews']: value, } = payload[0].payload;
     

      return (

        <div className="custom-tooltip" style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
          <p style={{ margin: '0px', fontSize: '12px', fontWeight: '500', color: 'rgba(34, 34, 34, 1)' }}>{formattedDate(label) || 'March 15, 2023'}</p> {/* Display the date */}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p style={{ display: 'flex', alignItems: 'center', margin: '0px', marginTop: '7px', marginBottom: '5px', fontSize: '12px', fontWeight: '400', color: 'rgba(99, 99, 99, 1)' }}>
              <span style={{
                background: 'rgba(53, 60, 247, 1)',
                color: 'rgba(53, 60, 247, 1)',
                height: '11px',
                width: '11px',
                borderRadius: '2px',
                display: 'inline-block',
                marginRight: '8px' // This ensures space between the circle and the text
              }} /><strong>{ylabel} :</strong> </p>
            <p style={{ margin: '0px', marginTop: '7px', marginBottom: '5px', fontSize: '12px', fontWeight: '400', color: 'rgba(99, 99, 99, 1)' }}>{value}</p>
          </div>
          {/* {likes && <p><strong>Likes:</strong> {likes}</p>} Display likes */}
          {/* {comments && <p><strong>Comments:</strong> {comments}</p>} Display comments */}
        </div>
      );
    }

    return null;
  };

  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      // Billion
      return (num / 1_000_000_000).toFixed(1) + 'B';
    } else if (num >= 1_000_000) {
      // Million
      return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      // Thousand
      return (num / 1000).toFixed(1) + 'k';
    } else {
      return num; // Return the number as is if it's less than 1000
    }
  }
  return (
    <div ref={parentRef} style={{ width: '100%', height: '100%', paddingBottom: '3%' }}>
      {data?.length > 0 ? (<AreaChart width={numericWidth} height={height} data={data}
        margin={{ top: 20, right: 40, left: 10, bottom: 40 }} // Adjust margins here

      >
        <XAxis
          dataKey='date'
          label={{
            value: 'Timeline',
            position: 'insideBottom',
            fill: 'black',
            offset: -35,
            // offset: -30,
            fontWeight: 'bold',
            fontSize: 14,
          }}
          interval={data.length > 20 ? Math.ceil(data.length / 20) : 0}
          // dy={4}
          dy={15}
          dx={-20}
          tick={{ fontSize: 9, fontWeight: 'bold', angle: -35 }}

        // tick={{ fontSize: 9, angle: -10 }}
        >
          {/* <Label
            value="Timeline"
            offset={-25}
            position='insideBottom'
            fontWeight={500}
            fill='#222'
            fontSize={14}
          /> */}
        </XAxis>
        {/* <YAxis
          type='number'
          domain={[minYValue - domainPadding, maxYValue + domainPadding]} 
          tick={{ fontSize: 12 }}
          tickCount={6}
          tickFormatter={(value) => formatNumber(value)}
          label={{
            value: { ylabel },
            position: 'insideBottom',
            fill: 'black',
            offset: -30,
            fontWeight: 'bold',
            fontSize: 14,
          }}
          dy={8}
          dx={-20}
          interval={data.length > 20 ? Math.ceil(data.length / 20) : 0}

        > */}
        {/* <Label
            value={ylabel}
            offset={0}
            // offset={showTitle === 'Trends in User Activity' ? -10 : -4}
            fontSize={14}
            position='insideLeft'
            angle={-90}
            fontWeight={500}
            fill='#222'
          /> */}
        <YAxis
          type="number"
          tick={{ fontSize: 11 }}
          tickCount={6}
          tickFormatter={(value) => formatNumber(value)} // Use the formatNumber function
          label={{
            value: ylabel,
            // position: 'insideBottom',
            fill: 'black',
            // offset: -4,
            fontWeight: 'bold',
            fontSize: 14,
            angle:-90,
            dy:8,
            dx:-30
          }}
          // dy={8}
          // dx={-20}
          interval={data.length > 20 ? Math.ceil(data.length / 20) : 0}
        />

        {/* </YAxis> */}
        {/* {
          showTitle === 'Trends in User Activity' ?
            <Tooltip content={<CustomTooltip ylabel={ylabel} />} />
            :
            <Tooltip content={<CustomTooltip2 ylabel={ylabel} />} />
        } */}
          {/* />
        </YAxis> */}
        {showTitle === 'Trends in User Activity' ? (
          <Tooltip
            content={<CustomTooltip ylabel={ylabel} typeVal={typeVal} />}
          />
        ) : (
          <Tooltip content={<CustomTooltip2 ylabel={ylabel} />} />
        )}
        {/* <Tooltip /> */}
        {
          showTitle === 'Trends in User Activity' ?

            <Area type="monotone" dataKey="engagement_count" stroke="#6F62FE" />
            :

            <Area type="monotone" dataKey={ylabel?.toLowerCase() || 'reviews'} stroke="#6F62FE" />
        }
        {/* <Area dataKey={'engagement_count'} fill='#6F62FE' stroke='#6F62FE' /> */}
      </AreaChart>)
        : (
          <NoDataWrapper>
            {/* No Data Available  */}Insufficient Data
          </NoDataWrapper>
        )}
    </div>
  );
};

export default AreaGraph;
